import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import uploadApiServiceV1 from '@services/upload/v1/uploadApi'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { ServerType } from '@utils/functions/createServerSideDatasource'
import { losslessNumberParser } from '@utils/functions/losslessNumberParser'
import logger from '@utils/logger'
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController'
import axios from 'axios'
import { queryClient } from 'src/queryClient'

import { Modality } from '../pages/projectForm/projectFormFields/ProjectFormFields.types'
import { DEFAULT_STALE_TIME_MS } from './common'
import { queryKeys as datasetsQueryKeys } from './datasets/constants'
import { datasetsQueryFn } from './datasets/queries'

const datasetApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasets`,
  withCredentials: true,
  transformResponse: (res) => losslessNumberParser(res),
})

const dashboardDatasetsQueryFn = async (): Promise<DatasetRowProps[]> => {
  const res = await queryClient.fetchQuery({
    queryKey: datasetsQueryKeys.datasets.all(),
    queryFn: datasetsQueryFn,
  })
  const rows = [...res]
  const incompleteDatasets = rows.filter((row: DatasetRowProps) => !row.complete && row.upload_id)
  const progressData = await Promise.all(
    incompleteDatasets.map(async (row: DatasetRowProps) => {
      return uploadApiServiceV1.getUploadProgressByPhase(row.upload_id)
    })
  )
  incompleteDatasets.forEach((row: DatasetRowProps, i: number) => {
    row.progress = progressData[i].progress
    row.status = progressData[i].status
  })

  return rows
}

export const dashboardDatasetsQueryOptions = () => ({
  queryKey: datasetsQueryKeys.datasets.dashboard(),
  queryFn: dashboardDatasetsQueryFn,
  staleTime: DEFAULT_STALE_TIME_MS,
})

const getDatasets = async (): Promise<DatasetRowProps[]> => {
  try {
    return await queryClient.fetchQuery(dashboardDatasetsQueryOptions())
  } catch {
    return []
  }
}

interface RowDataProps {
  id: string
  name?: string
  modality?: string
  upload_date?: string
}

const updateRow = async (rowData: RowDataProps | { id: string; name: string }) => {
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.patch(`/${rowData.id}`, rowData, createAuthHeaders(accessToken))
  void queryClient.invalidateQueries(datasetsQueryKeys.datasets.all())
  return res.data
}

const deleteRow = async (datasetId: string) => {
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.delete(`/${datasetId}`, createAuthHeaders(accessToken))
  void queryClient.invalidateQueries(datasetsQueryKeys.datasets.all())
  return res.data
}

export interface DatasetDetailsProps {
  name: string
  num_rows: number
  modality: Modality
  columns: string[]
  feature_columns: string[]
  label_columns: string[]
  possible_text_columns: string[]
  distinct_columns: string[]
  possible_label_columns: string[]
  identifier_columns: string[]
  text_column_guess: string
  label_column_guess: string
  image_columns: string[]
}

export enum FeatureTypeV1 {
  Image = 'image',
  Document = 'document',
  Untyped = 'untyped',
  Identifier = 'identifier',
}

export enum DataTypeV1 {
  Integer = 'integer',
  Float = 'float',
  Boolean = 'boolean',
  String = 'string',
  Date = 'date',
  Datetime = 'datetime',
  Time = 'time',
}

export enum ColumnTypeV1 {
  Untyped = 'untyped',
  Integer = 'integer',
  Float = 'float',
  Boolean = 'boolean',
  String = 'string',
  Image = 'image',
  ImageExternal = 'image_external',
  Document = 'document',
  DocumentExternal = 'document_external',
  Identifier = 'identifier',
  DateEpoch_s = 'date_epoch_s',
  DateEpoch_ms = 'date_epoch_ms',
  DateEpoch_us = 'date_epoch_us',
  DateEpoch_ns = 'date_epoch_ns',
  DateParse = 'date_parse',
  DatetimeEpoch_s = 'datetime_epoch_s',
  DatetimeEpoch_ms = 'datetime_epoch_ms',
  DatetimeEpoch_us = 'datetime_epoch_us',
  DatetimeEpoch_ns = 'datetime_epoch_ns',
  DatetimeParse = 'datetime_parse',
  TimeEpoch_s = 'time_epoch_s',
  TimeEpoch_ms = 'time_epoch_ms',
  TimeEpoch_us = 'time_epoch_us',
  TimeEpoch_ns = 'time_epoch_ns',
  TimeParse = 'time_parse',
}

export interface DatasetFieldTypesPropsV1 {
  [fieldName: string]: {
    feature_type: FeatureTypeV1
    data_type: DataTypeV1
    column_type: ColumnTypeV1
    mutable: boolean
  }
}
/**** *****/

export interface DatasetExamplesProps {
  columns: string[]
  rows: Record<string, unknown>[]
}

export interface DatasetRowProps {
  id: string
  name: string
  modality: string
  feature_columns: string[]
  label_columns: string[]
  possible_text_columns: string[]
  identifier_columns: string[]
  upload_date: number
  complete: boolean
  progress?: number
  status?: string
  upload_id: string
  num_rows?: number
  is_template?: boolean
}

export interface ColumnDiversity {
  has_minimal_diversity: boolean // at least 2 classes with at least 5 examples each
  has_non_represented_classes: boolean // there exists 1 class with <5 examples
  has_minimal_diversity_data_labeling: boolean // at least 2 classes
}

export interface DatasetDetailsPageInfoProps {
  num_rows: number
  name: string
  modality: string
  upload_date: number
  complete: boolean
  id_column: string
  upload_id: string
  columns: string[]
  image_columns: string[]
  media_url_columns: string[]
  progress?: number
  exceeds_rows_limit: boolean
}

const getDatasetDetails: (datasetId: string) => Promise<DatasetDetailsProps> = async (
  datasetId
) => {
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.get(`/details/${datasetId}`, createAuthHeaders(accessToken))
  return res.data
}

const getDatasetDetailsPageInfo = async (
  datasetId: string
): Promise<DatasetDetailsPageInfoProps> => {
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.get(
    `/dataset_details_page/${datasetId}`,
    createAuthHeaders(accessToken)
  )
  const details = res.data
  return details
}

const getDatasetFieldTypesV1 = async (datasetId: string): Promise<DatasetFieldTypesPropsV1> => {
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.get(`/field_types/${datasetId}`, createAuthHeaders(accessToken))
  logger.log(res.data)
  return res.data
}

const getDatasetExamples = async (
  datasetId: string,
  numRows: number,
  uploadId?: string
): Promise<DatasetExamplesProps> => {
  const url = `/examples/${datasetId}/${encodeURIComponent(numRows)}`
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.get(
    uploadId != null ? `${url}?upload_id=${uploadId}` : url,
    createAuthHeaders(accessToken)
  )
  return res.data
}

const checkColumnDiversity: (
  datasetId: string,
  column: string
) => Promise<ColumnDiversity> = async (datasetId, column) => {
  const url = `/diversity/${datasetId}/${encodeURIComponent(column)}`
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.get(url, createAuthHeaders(accessToken))
  return res.data
}

const checkValidMultilabelColumn = async (datasetId: string, column: string) => {
  const url = `/check_valid_multilabel/${datasetId}/${encodeURIComponent(column)}`
  const accessToken = await auth.getTokenSilently()
  const res = await datasetApi.get(url, createAuthHeaders(accessToken))
  return res.data
}

const copyDataset = async (userId: string, datasetId: string) => {
  const accessToken = await auth.getTokenSilently()
  const body = { dataset_id: datasetId, destination_user_id: userId }
  return datasetApi.post('/copy_dataset', body, createAuthHeaders(accessToken))
}

const createServer = (includeWarnings?: boolean): ServerType => {
  return {
    getData: function (cleansetId, request) {
      const startRow = request.startRow
      const endRow = request.endRow
      const sortModel = request.sortModel
      const filterModel = request.filterModel
      return getRows(
        cleansetId,
        startRow || 0,
        endRow || 0,
        sortModel,
        filterModel,
        includeWarnings
      )
    },
  }
}

const getRows = async (
  datasetId: string,
  startRow: number,
  endRow: number,
  sortModel?: SortModelItem[],
  filterModel?: {
    [key: string]: Record<string, unknown>
  },
  includeWarnings?: boolean
) => {
  const url = `/${datasetId}`

  const body = {
    start_row: startRow,
    end_row: endRow,
    sort: sortModel ? sortModel.map((elt) => [elt.colId, elt.sort]) : null,
    filter: filterModel || null,
    include_warnings: includeWarnings,
  }
  const accessToken = await auth.getTokenSilently()
  const response = await datasetApi.post(url, body, createAuthHeaders(accessToken))
  logger.log(response)
  return response.data
}

const apiMethods = {
  getDatasetFieldTypesV1,
  getDatasets,
  getDatasetDetails,
  getDatasetDetailsPageInfo,
  getDatasetExamples,
  checkColumnDiversity,
  checkValidMultilabelColumn,
  updateRow,
  deleteRow,
  copyDataset,
  createServer,
}

export default apiMethods
