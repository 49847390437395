import { CLEANLAB_FRONTEND_COLUMN } from '../datasheet/Datasheet.types'

export enum PresetHeaders {
  Given = 'Given Label',
  Suggested = 'Suggested Label',
  SuggestedScore = 'Suggested Label Score',
  Corrected = 'Corrected Label',
  Issue = 'Label Issue',
  Outlier = 'Outlier',
  Unlabeled = 'Unlabeled',
  Ambiguous = 'Ambiguous',
  NearDuplicate = 'Near Duplicate',
  Issues = 'Issues',
}

export enum FilterOperator {
  Equals = 'equals',
  NotEquals = 'notEqual',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  GreaterThan = 'greaterThan',
  Contains = 'contains',
  NotContains = 'notContains',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  Set = 'set',
  All = 'All',
}

export interface FilterData {
  property: string
  filterType: FilterOperator
  filterInput: string | number | string[]
  headerName: string
  agGridType: string
  isOpen: boolean
  isPreset: boolean
  applicationOrder?: number
  isChecked?: boolean
  hide?: boolean
}

export interface FilterState {
  filterData: FilterData[]
  addFilterMenuOpen: boolean
  isMultilabel: boolean
}

export enum CleansetFilterActions {
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  APPLY_FILTER = 'APPLY_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',
  CLOSE_ALL_FILTERS = 'CLOSE_ALL_FILTERS',
  TOGGLE_FILTER_OPEN = 'TOGGLE_FILTER_OPEN',
  TOGGLE_ADD_FILTER_OPEN = 'TOGGLE_ADD_FILTER_OPEN',
  CLEAR_AND_APPLY_MULTIPLE_FILTERS = 'CLEAR_AND_APPLY_MULTIPLE_FILTERS',
  ADD_TAG_FILTER = 'ADD_TAG_FILTER',
  REMOVE_TAG_FILTER = 'REMOVE_TAG_FILTER',
}

export interface ApplyFilterType {
  type: CleansetFilterActions.APPLY_FILTER
  filterData: FilterData
}
export interface RemoveFilterType {
  type: CleansetFilterActions.REMOVE_FILTER
  filterProperty: string
}
export interface ClearAllFiltersType {
  type: CleansetFilterActions.CLEAR_ALL_FILTERS
}

export interface CloseAllFiltersType {
  type: CleansetFilterActions.CLOSE_ALL_FILTERS
}

export interface ToggleFilterOpenType {
  type: CleansetFilterActions.TOGGLE_FILTER_OPEN
  filterProperty: string
}

export interface ToggleAddFilterOpenType {
  type: CleansetFilterActions.TOGGLE_ADD_FILTER_OPEN
}

export interface ApplyMultipleFiltersType {
  type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS
  filterData: FilterData[]
}

export interface AddTagFilterType {
  type: CleansetFilterActions.ADD_TAG_FILTER
  filterData: FilterData
}
export interface RemoveTagFilterType {
  type: CleansetFilterActions.REMOVE_TAG_FILTER
  filterData: FilterData
}

export type FilterActionType =
  | ApplyFilterType
  | RemoveFilterType
  | ClearAllFiltersType
  | CloseAllFiltersType
  | ToggleFilterOpenType
  | ToggleAddFilterOpenType
  | ApplyMultipleFiltersType
  | AddTagFilterType
  | RemoveTagFilterType

export type UseFiltersReturnType = {
  state: FilterState
  dispatch: React.Dispatch<FilterActionType>
}

export const PRESET_FILTER_PROPERTIES: string[] = [
  CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
  CLEANLAB_FRONTEND_COLUMN.CORRECTED,
  CLEANLAB_FRONTEND_COLUMN.ACTION,
  CLEANLAB_FRONTEND_COLUMN.ISSUE,
  CLEANLAB_FRONTEND_COLUMN.OUTLIER,
  CLEANLAB_FRONTEND_COLUMN.UNLABELED,
  CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS,
]
