import { Box } from '@chakra-ui/react'
import Loading from '@common/layout/loading/Loading'
import { useAuth } from '@hooks/useAuth'
import { useCleansetDetails, useCleansetProjectDetails } from '@services/cleanset/queries'
import React, { useReducer } from 'react'

import { useParamsCleansetId } from '../cleanset/Cleanset.helpers'
import { CleansetContext } from '../cleanset/CleansetContext'
import { FilterContext, FilterDispatchContext } from '../cleanset/filterReducer/FilterContext'
import { filterReducer } from '../cleanset/filterReducer/FilterReducer'
import { initialFilterState } from '../cleanset/filterReducer/InitialFilterState'
import { TabsContext, TabsDispatchContext } from '../cleanset/tabsReducer/TabsContext'
import { tabsReducer } from '../cleanset/tabsReducer/TabsReducer'
import { returnInitialTabsState } from '../cleanset/tabsReducer/TabsState'
import Landing from '../landing/Landing'
import CleansetCharts from './CleansetCharts'

const CleansetChartsDisplay = () => {
  const { isAuthenticated, isLoading, error } = useAuth()
  const cleansetId = useParamsCleansetId()
  const projectDetails = useCleansetProjectDetails({ cleansetId })
  const cleansetDetails = useCleansetDetails({ cleansetId })

  // Initialize default filter state
  const [filterState, filterDispatch] = useReducer(filterReducer, initialFilterState(false))
  const searchParams = new URLSearchParams(window.location.search)
  const [tabsState, tabsDispatch] = useReducer(
    tabsReducer,
    returnInitialTabsState(searchParams.get('tab'))
  )
  if (isLoading) {
    return <Loading />
  }
  if (!isAuthenticated || error || !projectDetails || !cleansetDetails) {
    return <Landing />
  }

  return (
    <CleansetContext.Provider
      value={{
        projectId: cleansetDetails.projectId,
        datasetId: cleansetDetails.datasetId,
        cleansetId: cleansetId,
        modality: projectDetails.modality,
        tasktype: projectDetails.tasktype,
        version: cleansetDetails.version,
      }}
    >
      <FilterContext.Provider value={filterState}>
        <FilterDispatchContext.Provider value={filterDispatch}>
          <TabsContext.Provider value={tabsState}>
            <TabsDispatchContext.Provider value={tabsDispatch}>
              <Box padding="0 2.5rem">
                <CleansetCharts
                  projectName={projectDetails.projectName}
                  firstGridDataRendered={true}
                  isInitialFetch={true}
                />
              </Box>
            </TabsDispatchContext.Provider>
          </TabsContext.Provider>
        </FilterDispatchContext.Provider>
      </FilterContext.Provider>
    </CleansetContext.Provider>
  )
}

export default CleansetChartsDisplay
