import { ButtonClose } from '@components/buttonClose/ButtonClose'
import { Kbd } from '@components/kbd/Kbd'
import { useKeyPress } from '@hooks/useKeyPress'
import { cn } from '@utils/tailwindUtils'
import { useCallback } from 'react'

import { EXCLUDE_COMMAND_NAME } from '../CommandPalette.helpers'
import { ActionType } from '../CommandPalette.types'
import {
  capAndRoundProbability,
  displayedTag,
  getBorderStyle,
  highlightSubstring,
  InvalidLabelTypeWarning,
} from './PaletteSuggestion.helpers'
import { PaletteSuggestionProps } from './PaletteSuggestion.types'

const PaletteSuggestion = (props: PaletteSuggestionProps) => {
  const {
    inputValue,
    command,
    category,
    name,
    shortcut,
    proba,
    isMultiSelect,
    givenLabelType,
    correctedLabel,
    markUnresolvedAction,
    currentRowAction,
    showTallView,
    newlyAddedLabels,
    showXLView,
  } = props
  const isInvalidLabelType = givenLabelType === 'number' && Number.isNaN(Number(inputValue))

  const isAppliedAction =
    (!isMultiSelect && correctedLabel === name) ||
    (currentRowAction === 'exclude' && name === 'Exclude')
  const isNewlyAddedLabel = newlyAddedLabels?.includes(name)

  const showProbability =
    category !== ActionType.EXCLUDE &&
    category !== ActionType.ADD_LABEL &&
    name !== EXCLUDE_COMMAND_NAME &&
    !isMultiSelect &&
    !isNewlyAddedLabel

  const handleCommandKeyPress = useCallback(() => {
    command()
  }, [command])

  useKeyPress({
    callback: handleCommandKeyPress,
    keys: [shortcut ? shortcut.toLowerCase() : 'NULL'],
  })

  if (isInvalidLabelType) {
    return <InvalidLabelTypeWarning />
  }

  const buttonClass = cn(
    'type-caption h-full w-full overflow-hidden rounded-2 border border-border-0 bg-surface-0 p-4 py-4 shadow-elev-0',
    'hover:bg-surface-0-hover active:bg-surface-0-active',
    'focus-visible:outline-blue-700 focus-visible:ring focus-visible:ring-focus',
    getBorderStyle(category, isAppliedAction)
  )

  return (
    <button tabIndex={0} className={buttonClass} onClick={command}>
      <div className="palette-row flex w-full items-center justify-between gap-4">
        {shortcut && <Kbd>{shortcut}</Kbd>}
        <div className="text-md type-caption flex flex-1 items-center ">
          <p className="palette-label-text line-clamp-1 overflow-hidden text-ellipsis break-all">
            {highlightSubstring(name, inputValue)}
          </p>
          {showProbability && <p className="pl-4">({capAndRoundProbability(proba)}%)</p>}
        </div>
        <div className="flex flex-shrink-0 items-center gap-3">
          {!showTallView && displayedTag(category, isAppliedAction, isNewlyAddedLabel, false, name)}
          {isAppliedAction && (
            <ButtonClose
              className="-my-3"
              onClick={(event) => {
                event.stopPropagation()
                markUnresolvedAction()
              }}
              size="small"
              aria-label="Unresolve action"
            />
          )}
        </div>
      </div>
      {showTallView && (
        <div className="palette-badges-tall flex pl-[28px] pt-4">
          {displayedTag(category, isAppliedAction, isNewlyAddedLabel, showXLView, name)}
        </div>
      )}
    </button>
  )
}

export default PaletteSuggestion
