import { Switch, useDisclosure } from '@chakra-ui/react'
import UploadDropzone from '@common/uploadDropzone/UploadDropzone'
import { FormField } from '@components/formField/FormField'
import { EnabledModelTypesRes } from '@services/project/constants'
import { Updater } from '@tanstack/react-form'
import { useId } from 'react'
import testIds from 'src/playwright/testIds'

import {
  CreateProjectContent,
  HeadingWrapper,
  PageDescription,
  PageHeading,
} from './CreateProject.helpers'
import { MlMode, SelectMLPrefsForm } from './CreateProjectSelectMLPrefs'
import PredProbsInfoModal from './predProbsInfoModal/PredProbsInfoModal'
import { isUploadReady, uploadHasError } from './projectFormFields/ProjectFormFields.helpers'
import { isModality, Modality } from './projectFormFields/ProjectFormFields.types'
import { SelectModelType } from './SelectModelType'
import { InlineLink } from './Sidebar'

const UPLOAD_ERRORS = {
  NO_FILE: 'No predicted probabilities uploaded. You need to upload your predicted probabilities.',
  UPLOAD_FAILED: 'File failed to upload. Please check your connection and try again.',
  UPLOAD_PENDING: 'Upload pending. Please wait for the upload to complete before submitting.',
} as const

export const MLPreferences = ({
  enabledModelTypes,
  form,
  modality,
}: {
  enabledModelTypes: EnabledModelTypesRes
  form: SelectMLPrefsForm
  modality: string
}) => {
  return (
    <form.Field
      name="mlMode"
      validators={{
        onSubmit: ({ value }) => {
          if (value !== 'custom' && value !== 'auto') {
            return 'You must select ML preferences'
          }
          return undefined
        },
      }}
    >
      {({ state, handleChange }) => {
        return (
          <CreateProjectContent>
            <HeadingWrapper>
              <PageHeading>Select a time-performance trade-off</PageHeading>
              <PageDescription>
                Pick a Project setting and you will get an email when the results are ready.
              </PageDescription>
            </HeadingWrapper>
            <div className="flex flex-col gap-6">
              <MlModeSelector handleChange={handleChange} value={state.value} />
              {state.value === 'auto' && (
                <SelectModelType
                  form={form}
                  enabledModelTypes={enabledModelTypes}
                  modality={isModality(modality) ? modality : Modality.text}
                />
              )}
              {state.value === 'custom' && <UploadPanel form={form} />}{' '}
            </div>
          </CreateProjectContent>
        )
      }}
    </form.Field>
  )
}

const UploadPanel = ({ form }: { form: SelectMLPrefsForm }) => {
  const { isOpen: isPsxInfoOpen, onOpen: onPsxInfoOpen, onClose: onPsxInfoClose } = useDisclosure()

  return (
    <div>
      <PredProbsInfoModal onClose={onPsxInfoClose} isOpen={isPsxInfoOpen} />
      <form.Field
        name="predProbsFile"
        validators={{
          onSubmit: ({ value, fieldApi }) => {
            const mlMode = fieldApi.form.getFieldValue('mlMode')
            const fileName = value?.filename

            if (mlMode === 'custom') {
              if (!fileName) {
                return UPLOAD_ERRORS.NO_FILE
              } else if (uploadHasError(value)) {
                return UPLOAD_ERRORS.UPLOAD_FAILED
              } else if (!isUploadReady(value)) {
                return UPLOAD_ERRORS.UPLOAD_PENDING
              }
            }
            return undefined
          },
        }}
      >
        {({ handleChange, state }) => (
          <FormField error={state.meta.errors?.[0]}>
            <div
              className="flex flex-col gap-4"
              data-testid={testIds.createProjectPageUploadPredProbsDropzone}
            >
              <UploadDropzone
                onUpdateFiles={(files) => {
                  handleChange({
                    filename: files?.[0]?.filename,
                    status: files?.[0]?.status,
                  })
                }}
                onProcessFile={(error, file) => {
                  handleChange({
                    filename: file.filename,
                    status: file.status,
                  })
                }}
                uploadType="pred_probs"
                maxFileSize="600MB"
                timeout={1000 * 60 * 15} // 15 minute timeout
              />
              <p className="type-body-100 text-text-faint">
                Probability data inputs should be formatted as detailed{' '}
                <InlineLink
                  to=""
                  onClick={(e) => {
                    e.preventDefault()
                    onPsxInfoOpen()
                  }}
                >
                  here
                </InlineLink>
                .
              </p>
            </div>
          </FormField>
        )}
      </form.Field>
    </div>
  )
}

function MlModeSelector({
  value,
  handleChange,
}: Readonly<{
  handleChange: (updater: Updater<MlMode, MlMode>) => void
  value: MlMode
}>) {
  const id = useId()
  return (
    <label className="type-label flex gap-4 text-text-strong" htmlFor={id}>
      <Switch
        isChecked={value === 'custom'}
        size="sm"
        onChange={() => handleChange((prev) => (prev === 'auto' ? 'custom' : 'auto'))}
        id={id}
      />
      Upload your own predicted probabilities
    </label>
  )
}
