import Loading from '@common/layout/loading/Loading'
import { Button } from '@components/button/Button'
import { FormField } from '@components/formField/FormField'
import { Input } from '@components/input/Input'
import { useForm } from '@tanstack/react-form'
import { FormEventHandler, Suspense, useCallback, useEffect } from 'react'
import { Link, LoaderFunction, redirect, useNavigate, useParams } from 'react-router-dom'

import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  getProjectNameFromDatasetName,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { DatasetDetailsFields } from './CreateProjectFromDataset'
import { NameProjectPageHeading } from './CreateProjectFromDemoId'
import { useDatasetDetailsOutletContext } from './DatasetDetailsOutlet'
import { ProjectNameField } from './ProjectNameField'
import {
  ProjectWizardStep,
  ProjectWizardStepper,
  useProjectWizardStore,
} from './projectWizard/ProjectWizard.store'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcome,
} from './Sidebar'

const THIS_STEP = 'selectDataset' as const satisfies ProjectWizardStep

const Form = () => {
  const navigate = useNavigate()
  const setValuesForStep = useProjectWizardStore((state) => state.setValuesForStep)
  const datasetId = useParams().datasetId

  const { datasetDetails } = useDatasetDetailsOutletContext()
  useEffect(() => {
    if (!datasetDetails) {
      navigate('/')
    }
  }, [datasetDetails, navigate])

  const form = useForm({
    defaultValues: {
      projectName: getProjectNameFromDatasetName(datasetDetails?.name) ?? '',
      datasetId: datasetId,
    },
    onSubmit: async ({ value }) => {
      if (!datasetId) {
        return
      }
      setValuesForStep(THIS_STEP, {
        datasetId: datasetId,
        projectName: value.projectName ?? '',
        startPoint: 'datasetId',
      })
      const url = `/clean/${value.datasetId}/type`
      navigate(url)
    },
  })
  const formState = form.useStore((s) => ({
    canSubmit: s.canSubmit,
  }))

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      form.handleSubmit()
    },
    [form]
  )

  return (
    <form className="flex flex-col gap-8" onSubmit={onSubmit} noValidate>
      <div className="flex flex-col gap-6">
        <FormField required={true} label="Dataset name">
          <Input disabled value={datasetDetails.name}></Input>
        </FormField>
        <ProjectNameField form={form} />
      </div>
      <DatasetDetailsFields datasetDetails={datasetDetails} />
      <ProjectWizardActions step={THIS_STEP}>
        <Button variant="highContrast" size="medium" type="submit" disabled={!formState.canSubmit}>
          Next
        </Button>
      </ProjectWizardActions>
    </form>
  )
}

export const createProjectFromDatasetIdLoader: LoaderFunction = ({ request }) => {
  const { resetState } = useProjectWizardStore.getState()
  const url = new URL(request.url)
  const resetParam = url.searchParams.get('reset')
  if (typeof resetParam === 'string') {
    url.searchParams.delete('reset')
    resetState()
    return redirect(url.toString())
  }
  return true
}

const CreateProjectFromDatasetId = () => {
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          <Link to="/">Dashboard</Link>
        </PreviousLink>
        <ProjectWizardStepper step={THIS_STEP} />
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <NameProjectPageHeading />
          <Suspense fallback={<Loading />}>
            <Form />
          </Suspense>
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/data_labeling/">
              Cleanlab Studio for data labeling
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/understanding_data/">
              Understanding your data
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/tlm/">
              Trustworthy language model quickstart
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}

export default CreateProjectFromDatasetId
