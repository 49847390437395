import { dataLoaderJsonResponse } from '@utils/dataLoaders/dataLoaderJsonResponse'
import { RouteObject } from 'react-router-dom'

import CreateProject from './pages/projectForm/CreateProject'
import CreateProjectFromDataset, {
  createProjectFromDatasetLoader,
} from './pages/projectForm/CreateProjectFromDataset'
import CreateProjectFromDatasetId, {
  createProjectFromDatasetIdLoader,
} from './pages/projectForm/CreateProjectFromDatasetId'
import CreateProjectFromDemo from './pages/projectForm/CreateProjectFromDemo'
import {
  CreateProjectFromDemoId,
  createProjectFromDemoIdLoader,
} from './pages/projectForm/CreateProjectFromDemoId'
import {
  CreateProjectSelectLabel,
  createProjectSelectLabelLoader,
} from './pages/projectForm/CreateProjectSelectLabel'
import { CreateProjectSelectMLPrefs } from './pages/projectForm/CreateProjectSelectMLPrefs'
import {
  CreateProjectSelectTypes,
  createProjectSelectTypesLoader,
} from './pages/projectForm/CreateProjectSelectTypes'
import { DatasetDetailsOutlet } from './pages/projectForm/DatasetDetailsOutlet'
import { createProjectSelectMLPrefsLoader } from './pages/projectForm/projectWizard/ProjectWizardActions'
import { ProjectWizardRootLayout } from './pages/projectForm/projectWizard/ProjectWizardRootLayout'

export const projectWizardRoutes: RouteObject = {
  path: '/clean',
  element: <ProjectWizardRootLayout />,
  loader: () => dataLoaderJsonResponse({ globals: { pageTitle: 'Create new Project' } }),
  children: [
    { path: '', element: <CreateProject /> },
    // Create from demo data routes
    {
      path: 'demo',
      children: [
        { path: '', element: <CreateProjectFromDemo /> },
        {
          path: ':demoId',
          element: <CreateProjectFromDemoId />,
          loader: createProjectFromDemoIdLoader,
        },
      ],
    },
    // Create from Dataset routes
    {
      children: [
        {
          path: 'dataset',
          element: <CreateProjectFromDataset />,
          loader: createProjectFromDatasetLoader,
        },
        {
          path: ':datasetId',
          element: <DatasetDetailsOutlet />,
          children: [
            {
              path: '',
              element: <CreateProjectFromDatasetId />,
              loader: createProjectFromDatasetIdLoader,
            },
            {
              path: 'type',
              element: <CreateProjectSelectTypes />,
              loader: createProjectSelectTypesLoader,
            },
            {
              path: 'label',
              element: <CreateProjectSelectLabel />,
              loader: createProjectSelectLabelLoader,
            },
            {
              path: 'ml',
              element: <CreateProjectSelectMLPrefs />,
              loader: createProjectSelectMLPrefsLoader,
            },
          ],
        },
      ],
    },
  ],
}
