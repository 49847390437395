import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { ServerType } from '@utils/functions/createServerSideDatasource'
import { losslessNumberParser } from '@utils/functions/losslessNumberParser'
import logger from '@utils/logger'
import { AnyObject } from '@utils/types'
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController'
import axios from 'axios'

import { ExportType } from '../pages/cleanset/exportDatasheetModal/ExportDatasheetModal.types'
import { CorrectionDistributionType } from '../pages/cleansetCharts/CleansetCharts.types'

const datasheetApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasheets`,
  withCredentials: true,
  transformResponse: (res) => losslessNumberParser(res),
})

// We need to handle responses specially when server responds with arrayBuffer
// transformResponse parses responses with JSON (default)
const datasheetFileApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasheets`,
  withCredentials: true,
})

const createServer = (): ServerType => {
  return {
    getData: function (cleansetId, request) {
      const startRow = request.startRow
      const endRow = request.endRow
      const sortModel = request.sortModel
      const filterModel = request.filterModel
      return getRows(cleansetId, startRow || 0, endRow || 0, sortModel, filterModel)
    },
  }
}

const getRows = async (
  cleansetId: string,
  startRow: number,
  endRow: number,
  sortModel?: SortModelItem[],
  filterModel?: {
    [key: string]: Record<string, unknown>
  }
) => {
  const url = `/${cleansetId}`

  const body = {
    start_row: startRow,
    end_row: endRow,
    sort: sortModel ? sortModel.map((elt) => [elt.colId, elt.sort]) : null,
    filter: filterModel ? filterModel : null,
  }
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.post(url, body, createAuthHeaders(accessToken))
  return response.data
}

const getColumns = async (cleansetId: string) => {
  const url = `/columns/${cleansetId}`
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.get(url, createAuthHeaders(accessToken))
  return response.data
}

const updateRowAction = async (cleansetId: string, rowId: string, action: string) => {
  const url = `/${cleansetId}/${encodeURIComponent(rowId)}`
  const body = {
    action,
  }
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.patch(url, body, createAuthHeaders(accessToken))
  return response.data
}

const updateRowCustomTag = async (cleansetId: string, rowId: string, tag: string) => {
  const url = `/${cleansetId}/${encodeURIComponent(rowId)}`
  const body = {
    tag,
  }
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.patch(url, body, createAuthHeaders(accessToken))
  return response.data
}

const batchActionTopKIssues = async (
  cleansetId: string,
  k: number,
  batchAction: string,
  relabel: string | number | boolean,
  filterModel: {
    [key: string]: Record<string, unknown>
  },
  sort: ((string | null | undefined)[] | undefined)[]
) => {
  const url = `/${cleansetId}/batch/${batchAction}`
  const body = { k, filter: filterModel ? filterModel : null, new_label: relabel, sort: sort }
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.patch(url, body, createAuthHeaders(accessToken))
  return response.data
}

export type ColumnAndValue = [string, number]

export type FeatureType =
  | 'categorical'
  | 'numeric'
  | 'datetime'
  | 'text'
  | 'boolean'
  | 'identifier'
  | 'image'

export interface DistributionDataType {
  feature_type: FeatureType
  data: {
    all: ColumnAndValue[]
    issue: ColumnAndValue[]
  }
}

export interface ExclusionDistributionDataType {
  exclusions: Record<string, number>
}

export interface CorrectionsDistributionDataType {
  corrections: Record<string, number>
}

const getLabelDistribution = async (
  cleansetId: string,
  compare: boolean
): Promise<DistributionDataType> => {
  const url = `/${cleansetId}/_compute?type=label_distribution&compare=${compare}`
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.get(url, createAuthHeaders(accessToken))
  return response.data
}

const getLabelCorrectionDistribution = async (
  cleansetId: string
): Promise<CorrectionsDistributionDataType> => {
  const url = `/${cleansetId}/_compute?type=corrections_distribution`
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.get(url, createAuthHeaders(accessToken))
  return response.data
}

const getExclusionDistribution = async (
  cleansetId: string
): Promise<ExclusionDistributionDataType> => {
  const url = `/${cleansetId}/_compute?type=exclusion_distribution`
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.get(url, createAuthHeaders(accessToken))
  return response.data
}

const getCorrectionDistribution = async (
  cleansetId: string
): Promise<CorrectionDistributionType> => {
  const url = `/${cleansetId}/_compute?type=correction_distribution`
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.get(url, createAuthHeaders(accessToken))
  return response.data
}

const getSuggestedCorrectionDistribution = async (
  cleansetId: string
): Promise<CorrectionDistributionType> => {
  const url = `/${cleansetId}/_compute?type=suggested_correction_distribution`
  const accessToken = await auth.getTokenSilently()
  const response = await datasheetApi.get(url, createAuthHeaders(accessToken))
  return response.data
}

const exportDatasheet = async (
  cleansetId: string,
  exportSetting: ExportType,
  filterModel: { [key: string]: string },
  displayedColumns: string[]
) => {
  const url = `/export/${cleansetId}`
  const body: AnyObject = {
    export_setting: exportSetting,
  }

  if (exportSetting === 'custom') {
    logger.info('filter model is', filterModel)
    body.filter = filterModel ? filterModel : null
    body.displayed_columns = displayedColumns
  }

  const accessToken = await auth.getTokenSilently()
  return datasheetFileApi.post(url, body, {
    ...createAuthHeaders(accessToken),
    responseType: 'arraybuffer',
  })
}

const apiMethods = {
  batchActionTopKIssues,
  createServer,
  getRows,
  getColumns,
  updateRowAction,
  updateRowCustomTag,
  getExclusionDistribution,
  getLabelCorrectionDistribution,
  getLabelDistribution,
  getCorrectionDistribution,
  getSuggestedCorrectionDistribution,
  exportDatasheet,
}

export default apiMethods
