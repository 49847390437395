import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import * as Sentry from '@sentry/browser'
import { queryKeys } from '@services/datasets/constants'
import { IS_PROD_ENV, REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { delay } from '@utils/functions/delay'
import { losslessNumberParser } from '@utils/functions/losslessNumberParser'
import axios, { AxiosResponse } from 'axios'
import { queryClient } from 'src/queryClient'

const uploadApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/upload/v1`,
  withCredentials: true,
  transformResponse: (res) => losslessNumberParser(res),
})

const initializeUpload = async (file: Blob, onError: (errorMessage: string) => void) => {
  const accessToken = await auth.getTokenSilently()
  try {
    const body = {
      size_in_bytes: file.size,
      filename: file.name,
      file_type: file.type,
    }
    const res = await uploadApi.post('/file/initialize', body, createAuthHeaders(accessToken))
    return res.data
  } catch (err) {
    onError(err as string)
  }
}

const getFileChunks = (file: Blob, chunkSizes: number[]) => {
  const chunkPositions = chunkSizes.reduce((acc: number[][], chunkSize: number, i: number) => {
    if (i === 0) {
      acc.push([0, chunkSize])
    } else {
      acc.push([acc[i - 1][1], acc[i - 1][1] + chunkSize])
    }
    return acc
  }, [])
  const chunks = chunkPositions.map(([chunkStart, chunkEnd]) => file.slice(chunkStart, chunkEnd))
  return chunks
}

const uploadChunk = async (
  post: string,
  fileChunks: Blob[],
  batchStart: number,
  batchIndex: number,
  onError: (errorMessage: string) => void,
  onProgress: () => void,
  retries = 3
): Promise<any> => {
  return axios
    .put(post, fileChunks[batchStart + batchIndex], {
      headers: { 'Content-Type': fileChunks[batchStart + batchIndex].type },
      timeout: 8000000, // allows uploading on 50 kbps connection
    })
    .then((res: AxiosResponse) => {
      onProgress()
      return res
    })
    .catch(async (err: any) => {
      if (retries > 0) {
        await delay(3000)
        return uploadChunk(
          post,
          fileChunks,
          batchStart,
          batchIndex,
          onError,
          onProgress,
          retries - 1
        )
      } else {
        // If retries are exhausted, enter Filepond error state
        onError(err)
        if (IS_PROD_ENV) {
          Sentry.captureException(`Error uploading chunk ${batchIndex} - ${err}`)
        }
      }
    })
}

const uploadFileChunks = async (
  fileChunks: Blob[],
  presignedPosts: string[],
  onProgress: (progressAmount: number, totalAmount: number) => void,
  onError: (errorMessage: string) => void
): Promise<UploadPart[]> => {
  const numChunks = fileChunks.length
  const batchSize = 10
  let numCompleted = 0
  const results: UploadPart[] = []
  while (numCompleted < numChunks) {
    const batchStart = numCompleted
    let batchNumCompleted = numCompleted

    const batchRes: Promise<AxiosResponse>[] = await Promise.all(
      presignedPosts
        .slice(batchStart, Math.min(batchStart + batchSize, numChunks))
        .map(async (post, i) => {
          return uploadChunk(post, fileChunks, batchStart, i, onError, () => {
            batchNumCompleted += 1
            onProgress(batchNumCompleted, numChunks)
          })
        })
    )

    // if any of the batch responses are undefined, then at least one chunk's retries were exhausted
    const isError = batchRes.some((res) => res === undefined)
    if (isError) {
      onError('Error uploading file')
      break
    } else {
      results.push(
        ...batchRes.map((resp: any, i: number) => ({
          ETag: resp?.headers?.etag?.replace(/(^"|"$)/g, ''),
          PartNumber: batchStart + i + 1,
        }))
      )
      numCompleted += batchSize
    }
  }
  return results
}

const uploadDatasetFileChunks = async (
  file: Blob,
  partSizes: number[],
  presignedPosts: string[],
  onProgress: (progressAmount: number, totalAmount: number) => void,
  onError: (errorMessage: string) => void
) => {
  const fileChunks = getFileChunks(file, partSizes)
  const uploadParts = await uploadFileChunks(fileChunks, presignedPosts, onProgress, onError)
  return uploadParts
}

const getDatasetId = async (uploadId: string) => {
  const accessToken = await auth.getTokenSilently()
  const res = await uploadApi.get(
    `/dataset_id?upload_id=${uploadId}`,
    createAuthHeaders(accessToken)
  )
  return res.data
}

const getUploadProgressByPhase = async (uploadId: string) => {
  const accessToken = await auth.getTokenSilently()
  const uploadIdHex = uploadId.replace(/-/g, '')
  const res = await uploadApi.get(
    `/progress_by_phase?upload_id=${uploadIdHex}`,
    createAuthHeaders(accessToken)
  )
  queryClient.invalidateQueries(queryKeys.datasets.dashboard())
  return res.data
}

const getUploadProgress = async (uploadId: string) => {
  const accessToken = await auth.getTokenSilently()
  const uploadIdHex = uploadId.replace(/-/g, '')
  const res = await uploadApi.get(
    `/total_progress?upload_id=${uploadIdHex}`,
    createAuthHeaders(accessToken)
  )
  return res.data
}

const requestEmail = async (uploadId: string) => {
  const accessToken = await auth.getTokenSilently()
  const res = await uploadApi.post(
    `/request_email?upload_id=${uploadId}`,
    {},
    createAuthHeaders(accessToken)
  )
  return res.data
}

const uploadFromUrl = async (url: string) => {
  const accessToken = await auth.getTokenSilently()
  const res = await uploadApi.post('/url/initialize', { url }, createAuthHeaders(accessToken))
  return res.data
}

export interface UploadPart {
  ETag?: string
  PartNumber: number
}

const completeUpload = async (uploadId: string, uploadParts: UploadPart[]) => {
  const accessToken = await auth.getTokenSilently()
  const res = await uploadApi.post(
    '/file/complete',
    { upload_id: uploadId, upload_parts: uploadParts },
    createAuthHeaders(accessToken)
  )
  return res.data
}

const completeFileUpload = async (uploadId: string, uploadParts: UploadPart[]) => {
  const uploadRes = await completeUpload(uploadId, uploadParts)
  return uploadRes.id
}

const confirmUpload = async (uploadId: string) => {
  const accessToken = await auth.getTokenSilently()
  const res = await uploadApi.post(
    '/confirm',
    { upload_id: uploadId },
    createAuthHeaders(accessToken)
  )
  return res.data
}

const updateSchema = async (datasetId: string, schemaUpdates: object) => {
  const accessToken = await auth.getTokenSilently()
  const schemaUpdatesList = Object.entries(schemaUpdates).map(([columnName, columnType]) => {
    return { name: columnName, column_type: columnType }
  })
  const body = { dataset_id: datasetId, schema_updates: schemaUpdatesList }
  const res = await uploadApi.patch('/schema', body, createAuthHeaders(accessToken))
  return res.data
}

const apiMethods = {
  getDatasetId,
  getUploadProgressByPhase,
  getUploadProgress,
  requestEmail,
  uploadFromUrl,
  completeFileUpload,
  initializeUpload,
  uploadDatasetFileChunks,
  confirmUpload,
  updateSchema,
}

export default apiMethods
