import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import {
  EnrichmentProjectsRes,
  queryKeys as enrichmentQueryKeys,
} from '@services/enrichment/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { losslessNumberParser } from '@utils/functions/losslessNumberParser'
import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import axios from 'axios'
import { queryClient } from 'src/queryClient'

import { DEFAULT_STALE_TIME_MS } from './common'
import {
  createServerSideDatasource as createServerSideDatasourceFromProjectApi,
  ServerType,
} from './projectApi'

export const enrichmentProjectApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/enrichment/v0`,
  withCredentials: true,
  transformResponse: (res) => losslessNumberParser(res),
})

export const enrichmentProjectsQueryFn = async ({ page = 1 }: { page: number }) => {
  const accessToken = await auth.getTokenSilently()

  // TODO: Figure out pagination with backend team
  const url = `projects?page=${page}`

  return (
    await enrichmentProjectApi.get<EnrichmentProjectsRes>(url, createAuthHeaders(accessToken))
  ).data
}

export const enrichmentProjectsQueryOptions = ({ page = 1 }: { page?: number }) => {
  const queryKey = enrichmentQueryKeys.enrichmentProjects.all({ page })
  return {
    queryKey,
    queryFn: () => enrichmentProjectsQueryFn({ page }),
    staleTime: DEFAULT_STALE_TIME_MS,
  } as const
}

const createServer = (): ServerType => {
  return {
    getData: async (_request: IServerSideGetRowsRequest) => {
      try {
        const res = await queryClient.fetchQuery(enrichmentProjectsQueryOptions({}))
        return {
          rows: res.projects,
          row_count: res.total_count,
        }
      } catch {
        return { rows: [], row_count: 0 }
      }
    },
  }
}

const apiMethods = {
  createServer,
  createServerSideDatasource: createServerSideDatasourceFromProjectApi,
}

export default apiMethods
