import {
  Center,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import Colors from '@common/Colors'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import { Input } from '@components/input/Input'
import Link from '@components/link/Link'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useExportCleanset } from '@services/billing/mutations'
import { useCleansetExportDetails } from '@services/billing/queries'
import { useApiKey } from '@services/commandLine/queries'
import { RowCountType } from '@services/datasheet/constants'
import { useRowCount } from '@services/datasheet/queries'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { downloadFileFromAxiosResponse } from '@utils/functions/downloadFile'
import { AxiosError, AxiosResponse } from 'axios'
import { useContext, useState } from 'react'
import { FiCheckCircle, FiInfo } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { CleansetContext } from '../CleansetContext'
import { radioButtonStyles } from './ExportDatasheetModal.helpers'
import { ExportDatasheetModalProps, ExportType } from './ExportDatasheetModal.types'

const ExportDatasheetModal = (props: ExportDatasheetModalProps) => {
  const {
    isOpen,
    onClose,
    cleansetId,
    gridApi,
    displayedColumns,
    projectId,
    isTemplate,
    firstGridDataRendered,
  } = props
  const { numRows } = useRowCount(cleansetId, RowCountType.TOTAL, gridApi, firstGridDataRendered)
  const filterModel = gridApi.getFilterModel()
  const [exportSetting, setExportSetting] = useState<ExportType>('default')
  const [exportTooLarge, setExportTooLarge] = useState<boolean>(false)
  const [showUsage, setShowUsage] = useState(false)
  const [showExportSuccessful, setShowExportSuccessful] = useState(false)

  const { apiKey, isFetching: isApiKeyLoading } = useApiKey()
  const toast = useToast()
  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()
  const { isIndividualPayingCustomer, payingCustomer, tier } = useSubscription()
  const {
    previouslyExported,
    numFreeRows,
    numOverageRows,
    pricePerRowExport,
    totalPrice,
    numFreeCleansetExportRowsRemaining,
    numTotalCleansetExportRowsRemaining,
  } = useCleansetExportDetails({
    projectId: projectId,
    isIndividualPlan: tier === 'individual',
  })

  const borderColor = useColorModeValue('gray.200', 'gray.500')
  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  const navigate = useNavigate()

  const isCleansetExportFree = !isIndividualPayingCustomer || previouslyExported

  const eventParameters = {
    ...cleansetInfo,
    numRows: numRows,
    pricePerRow: pricePerRowExport,
    exportSetting: exportSetting,
    tier: tier,
    isTemplate: isTemplate,
    previouslyExported: previouslyExported,
    numFreeRows: numFreeRows,
    numOverageRows: numOverageRows,
    totalPrice: totalPrice,
    numFreeCleansetExportRowsRemaining: numFreeCleansetExportRowsRemaining,
    numTotalCleansetExportRowsRemaining: numTotalCleansetExportRowsRemaining,
  }

  const resetModalAndClose = () => {
    setExportSetting('default')
    setShowUsage(false)
    setShowExportSuccessful(false)
    onClose()
  }

  const onExportSuccess = (res: AxiosResponse) => {
    setExportTooLarge(false)
    downloadFileFromAxiosResponse(res)
    setShowExportSuccessful(true)
    if (previouslyExported) {
      resetModalAndClose()
    }
    if (isCleansetExportFree) {
      toast({
        ...defaultToastProps,
        description: 'Your download has started.',
        status: 'success',
      })
    }
  }

  const onExportError = (err: AxiosError) => {
    if ((err as AxiosError).response?.status === 413) {
      setExportTooLarge(true)
    } else {
      notifyAxiosError(toast, err as AxiosError, {
        title: 'Cleanset export failed',
      })
    }
  }

  const { mutate: handleExport, isLoading: isExportLoading } = useExportCleanset({
    cleansetId: cleansetId,
    exportSetting: exportSetting,
    filterModel: filterModel,
    displayedColumns: displayedColumns,
    projectId: projectId,
    onSuccess: onExportSuccess,
    onError: onExportError,
  })

  return (
    <Modal isOpen={isOpen} onClose={resetModalAndClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent bg={modalBg} px="2rem" py="1rem">
        <ModalHeader fontSize="xl" fontWeight="bold">
          {showUsage ? 'Review Usage' : showExportSuccessful ? '' : 'Export datasheet'}
        </ModalHeader>
        <ModalCloseButton />
        {showExportSuccessful ? (
          <Stack>
            <VStack textAlign="center" fontSize="md" mt={6} mx={40}>
              <Center borderRadius={50} w="80px" h="80px" bgColor="green">
                <FiCheckCircle color="white" size="35%" />
              </Center>
              <Text fontSize="4xl">Export success!</Text>
              {!IS_VPC_ENVIRONMENT && (
                <Link
                  onClick={() => {
                    trackEvent(MixpanelEvents.clickViewReceiptButton, eventParameters)
                    navigate('/account?tab=Usage%26Billing')
                  }}
                >
                  View usage
                </Link>
              )}
              <Text>Your download should begin automatically. Didn't work? </Text>
              <Link
                onClick={() => {
                  trackEvent(MixpanelEvents.clickTryDownloadingAgainButton, eventParameters)
                  handleExport()
                }}
              >
                Try downloading again.
              </Link>
            </VStack>
            <Flex py={6} justify="flex-end">
              <SecondaryButton
                aria-label="close export modal"
                isDisabled={isExportLoading}
                onClick={resetModalAndClose}
              >
                Close
              </SecondaryButton>
            </Flex>
          </Stack>
        ) : payingCustomer && tier === 'individual' && showUsage ? (
          <ModalBody pt={0} fontSize="sm">
            <Center pt={2}>
              <VStack
                px="24px"
                mb="2.5rem"
                w="50%"
                alignItems="flex-start"
                spacing={4}
                border="1px solid"
                borderColor={borderColor}
                borderRadius="md"
              >
                <VStack pt={6} fontSize="sm" w="100%" gap={6}>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Free rows remaining (monthly)</Text>
                    <Text>{numFreeCleansetExportRowsRemaining}</Text>
                  </HStack>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Number of free rows used</Text>
                    <Text>{numFreeRows}</Text>
                  </HStack>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Number of rows overage</Text>
                    <Text>{numOverageRows}</Text>
                  </HStack>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Price per row overage</Text>
                    <Text>${pricePerRowExport / 100}</Text>
                  </HStack>
                  {totalPrice === 50 && (
                    <HStack w="100%" justify="space-between">
                      <Text fontWeight="500">Minimum payment</Text>
                      <Text>$0.50</Text>
                    </HStack>
                  )}
                </VStack>
                <Divider textAlign="center" color="#3C42571F" />
                <HStack
                  w="100%"
                  fontWeight="500"
                  fontSize="sm"
                  justify="space-between"
                  h="5rem"
                  align="start"
                  pt="12px"
                >
                  <Text>Total added to billing period</Text>
                  <Text>${(totalPrice / 100).toFixed(2)}</Text>
                </HStack>
              </VStack>
            </Center>
            <Text color={numTotalCleansetExportRowsRemaining < numRows ? 'red' : undefined}>
              {numTotalCleansetExportRowsRemaining < numRows
                ? 'You will exceed your monthly Cleanset export rows limit through this export. To increase your mothly limits, please contact us at '
                : 'You will be charged for any overage usage at the end of your current billing period. We offer discounts for a variety of groups, including enterprises, researchers, and startups. Contact us at '}{' '}
              <Link
                href="mailto:sales@cleanlab.ai"
                isExternal
                onClick={() => {
                  trackEvent(MixpanelEvents.clickContactUsButton, eventParameters)
                }}
              >
                sales@cleanlab.ai
              </Link>
              .
            </Text>

            <HStack justify="space-between" py="2rem">
              <SecondaryButton
                isDisabled={isExportLoading}
                onClick={() => {
                  setShowUsage(false)
                }}
              >
                Back: Export Dataset
              </SecondaryButton>
              <PrimaryButton
                height="40px"
                isLoading={isExportLoading}
                isDisabled={isExportLoading || numTotalCleansetExportRowsRemaining < numRows}
                onClick={() => {
                  trackEvent(MixpanelEvents.clickPayAndExportButton, eventParameters)
                  handleExport()
                  trackEvent(MixpanelEvents.startCleansetDownload, eventParameters)
                }}
                aria-label="Export Cleanset"
              >
                Export
              </PrimaryButton>
            </HStack>
          </ModalBody>
        ) : (
          <ModalBody fontSize="md">
            <Text>
              A CSV file of the Dataset will be generated, with selected Dataset columns, plus
              columns generated by Cleanlab.
              <br />
              You may generate exports of up to 100MB using this interface. For larger exports,{' '}
              <strong>use Export using API</strong>.
              <br />
              <br />
            </Text>
            <Text>
              <strong>Note:</strong> A one-time payment unlocks unlimited exports for this Project,
              regardless of export configuration. You will be billed for overage usage at the end of
              the monthly billing period.
            </Text>
            <br />
            <Tabs isFitted>
              <TabList fontSize="lg">
                <Tab>Export here</Tab>
                <Tab>Export using API</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <HStack>
                    <Text fontWeight="bold" my="1rem">
                      Select Export Configuration:
                    </Text>
                    <Tooltip
                      label="Cost is based on number of Dataset rows and is not affected by export configuration."
                      fontSize="md"
                    >
                      <span>
                        <FiInfo />
                      </span>
                    </Tooltip>
                  </HStack>
                  <RadioGroup
                    defaultValue="default"
                    onChange={(val) => setExportSetting(val as ExportType)}
                  >
                    <VStack spacing={2} align="left">
                      <Radio value="default" {...radioButtonStyles}>
                        <Flex>
                          <Text>
                            <strong>Default</strong> - All rows except those marked with the{' '}
                            <Text
                              as="span"
                              fontWeight={700}
                              color={Colors.ExportDatasheetModal.excludeText}
                              fontSize="md"
                            >
                              exclude
                            </Text>{' '}
                            action will be included. All columns will be included.
                          </Text>
                        </Flex>
                      </Radio>
                      {displayedColumns.length > 0 && (
                        <Radio value="custom" {...radioButtonStyles}>
                          <Flex>
                            <Text>
                              <strong>Custom</strong> - Only rows and columns shown by the current
                              table settings will be included.
                            </Text>
                          </Flex>
                        </Radio>
                      )}
                      <Radio value="everything" {...radioButtonStyles}>
                        <Flex>
                          <Text>
                            <strong>Everything </strong> - Export all rows and columns.
                          </Text>
                        </Flex>
                      </Radio>
                    </VStack>
                  </RadioGroup>
                  {exportTooLarge && (
                    <Text color="red" my="1rem">
                      Requested data is too large for export. Use the CLI instead.
                    </Text>
                  )}
                  <br />
                  <br />
                  <Flex justify="flex-end" my={2}>
                    <PrimaryButton
                      isLoading={isExportLoading}
                      height="40px"
                      aria-label="export cleanset"
                      onClick={() => {
                        trackEvent(
                          isCleansetExportFree
                            ? MixpanelEvents.clickModalExportButton
                            : MixpanelEvents.clickPricingAndPaymentButton,
                          eventParameters
                        )
                        if (isCleansetExportFree) {
                          handleExport()
                        } else {
                          setShowUsage(true)
                        }
                      }}
                    >
                      {isCleansetExportFree ? 'Export' : 'Next: Review Usage'}
                    </PrimaryButton>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <VStack justify="flex-start" align="flex-start" fontSize="md">
                    <Text>
                      You can use the{' '}
                      <Link href="https://help.cleanlab.ai/guide/quickstart/cli/" isExternal>
                        CLI
                      </Link>{' '}
                      or{' '}
                      <Link href="https://help.cleanlab.ai/guide/quickstart/api/" isExternal>
                        Python API
                      </Link>{' '}
                      to export just the Cleanlab columns (analysis results and corrections,
                      including the cleaned labels) or apply the corrections to your Dataset.
                    </Text>
                    <HStack gap="1rem" w="100%">
                      <VStack align="flex-start" w="100%">
                        <Text fontWeight="bold">Your API key is:</Text>
                        {!isApiKeyLoading && apiKey === null && (
                          <p>Unable to fetch your API key. Please try again. </p>
                        )}
                        {apiKey !== null && (
                          <Input canCopy size="large" variant="monospace" value={apiKey} />
                        )}
                      </VStack>

                      <VStack align="flex-start" w="100%">
                        <Text fontWeight="bold">Your Cleanset ID is:</Text>
                        <Input canCopy size="large" variant="monospace" value={cleansetId} />
                      </VStack>
                    </HStack>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ExportDatasheetModal
