import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import { RadioCard } from '@common/forms/radioCard/RadioCard'
import { RadioCardGroup } from '@common/forms/radioCard/RadioCardGroup'
import { useEventTracking } from '@hooks/useEventTracking'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { queryKeys, RowCountType } from '@services/datasheet/constants'
import { useRowCount } from '@services/datasheet/queries'
import datasheetApiService from '@services/datasheetApi'
import { AxiosError } from 'axios'
import { isNaN } from 'formik'
import { FormEvent, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

import { CleansetContext } from '../CleansetContext'
import { NUM_ROWS_PER_PAGE } from '../datasheet/Datasheet.types'
import BatchActionInput from './batchActionInput/BatchActionInput'
import {
  getActionColorScheme,
  getActionDescription,
  getBatchActionRequestPath,
  getBatchActionVerb,
  getSortModel,
} from './BatchActionsModal.helpers'
import {
  BatchAction,
  BatchActionsModalProps,
  multiLabelOptions,
  options,
} from './BatchActionsModal.types'

const BatchActionsModal = (props: BatchActionsModalProps) => {
  const queryClient = useQueryClient()
  const {
    isOpen,
    onClose,
    cleansetId,
    gridApi,
    relabelOptions,
    columnState,
    isMultiLabel,
    isTemplate,
    currPageNum,
    setCurrPageNum,
    fetchAndUpdateCurrRowData,
    firstGridDataRendered,
  } = props

  const { numRows: numRowsNonFiltered } = useRowCount(
    cleansetId,
    RowCountType.CUSTOM,
    gridApi,
    firstGridDataRendered
  )

  const { numRows: numTotalRows } = useRowCount(
    cleansetId,
    RowCountType.TOTAL,
    gridApi,
    firstGridDataRendered
  )

  const [buttonLoading, setButtonLoading] = useState(false)
  const [batchAction, setBatchAction] = useState<BatchAction>(BatchAction.autoFix)
  const [topKInput, setTopKInput] = useState('1')
  const [relabel, setRelabel] = useState<string | number | boolean | null>(null)

  const toast = useToast()
  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()

  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  const isInvalidNumber =
    isNaN(Number(topKInput)) ||
    Number(topKInput) > numRowsNonFiltered ||
    Number(topKInput) < 1 ||
    numRowsNonFiltered < 1

  const handleBatchActionFormSubmit = async (e: FormEvent) => {
    const topKBatchActionNumFiltered = Number(topKInput)

    e.preventDefault()

    if (isNaN(topKBatchActionNumFiltered)) {
      return
    }
    setButtonLoading(true)
    trackEvent(MixpanelEvents.applyBatchAction, {
      ...cleansetInfo,
      actionType: batchAction,
      numApplied: topKBatchActionNumFiltered,
      label: batchAction === BatchAction.label ? relabel : null,
      isTemplate: isTemplate,
    })
    try {
      await datasheetApiService.batchActionTopKIssues(
        cleansetId,
        topKBatchActionNumFiltered,
        getBatchActionRequestPath(batchAction),
        relabel !== null ? relabel.toString() : '',
        gridApi.getFilterModel(),
        getSortModel(columnState)
      )
      void queryClient.invalidateQueries(
        queryKeys.datasheet.id(cleansetId).rowCount().filterType(RowCountType.ISSUES_RESOLVED)
      )
      void queryClient.invalidateQueries(
        queryKeys.datasheet.id(cleansetId).rowCount().filterType(RowCountType.RESOLVED)
      )
      setTimeout(() => gridApi.refreshServerSideStore({}), 500)

      gridApi?.deselectAll()
      const rowNode = gridApi.getDisplayedRowAtIndex(topKBatchActionNumFiltered)
      const pageNum = Math.floor(topKBatchActionNumFiltered / NUM_ROWS_PER_PAGE)
      // Check if this batch action will land the user on another page of the grid.
      // If the node is on a page that hasn't rendered yet, utilize fetchAndUpdateCurrRowData
      // to select the new row node when it renders.
      if (pageNum !== currPageNum) {
        gridApi?.paginationGoToPage(pageNum)
        setCurrPageNum(pageNum)
        fetchAndUpdateCurrRowData(topKBatchActionNumFiltered)
      } else {
        rowNode?.setSelected(true)
      }

      toast({
        ...defaultToastProps,
        variant: 'solid',
        description: `Top ${topKInput} filtered datapoints have been ${getBatchActionVerb(
          batchAction
        )}!`,
        status: 'success',
      })
      setTopKInput('1')
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError, { title: 'Batch action failed' })
    } finally {
      onClose()
      setButtonLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Clean Top K</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="lg" px={6} pb={6}>
          <VStack
            as="form"
            w="100%"
            spacing="1rem"
            align="flex-start"
            onSubmit={handleBatchActionFormSubmit}
          >
            <Text>
              Apply an action to many datapoints at once. Actions selected here will affect the top
              data points given by the currently applied sort and filter.
            </Text>
            <RadioCardGroup
              justify="center"
              w="full"
              py="1rem"
              value={batchAction}
              onChange={(e) => {
                setBatchAction(e)
              }}
              direction="row"
            >
              {(isMultiLabel ? multiLabelOptions : options).map((e) => (
                <RadioCard key={e} noCircle value={e}>
                  {e}
                </RadioCard>
              ))}
            </RadioCardGroup>
            <BatchActionInput
              numRowsNonFiltered={numRowsNonFiltered}
              batchAction={batchAction}
              relabelOptions={relabelOptions}
              setRelabel={setRelabel}
              relabel={relabel}
              isInvalidNumber={isInvalidNumber}
              topKInput={topKInput}
              setTopKInput={setTopKInput}
            />
            <Flex align="center" h="120px" w="full">
              {getActionDescription(batchAction)}
            </Flex>
            <Text fontSize="sm">
              Your currently-applied filters are selecting {numRowsNonFiltered} out of a total of{' '}
              {numTotalRows} data points from your Dataset.
            </Text>
            <Flex w="100%" justify="flex-end">
              <Tooltip
                hasArrow
                label={
                  batchAction === BatchAction.label && relabel === null
                    ? 'Select a Label to apply to the chosen datapoints'
                    : ''
                }
              >
                <Button
                  type="submit"
                  colorScheme={getActionColorScheme(batchAction)}
                  mr={3}
                  isLoading={buttonLoading}
                  aria-label="batch action top issues"
                  isDisabled={
                    isInvalidNumber || (batchAction === BatchAction.label && relabel === null)
                  }
                >
                  <span>{batchAction}</span>
                </Button>
              </Tooltip>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default BatchActionsModal
