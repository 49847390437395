import { Box, Flex, Spinner, Stack, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import { useRestoreGridSettings } from '@hooks/useRestoreGridSettings'
import NonExistentResource from '@providers/errors/NonExistentResource'
import { useCleansetDetails, useCleansetProjectDetails } from '@services/cleanset/queries'
import { queryKeys } from '@services/datasheet/constants'
import { useCheckHighlyUnlabeledClass, useColumns } from '@services/datasheet/queries'
import { isUUID4 } from '@utils/functions/isUUID4'
import { ColumnApi, GridApi } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import CleansetCharts from '../cleansetCharts/CleansetCharts'
import { Tasktype } from '../projectForm/projectFormFields/ProjectFormFields.types'
import {
  deepCompareColumnStatuses,
  setInvalidIdOrRaiseToast,
  useParamsCleansetId,
} from './Cleanset.helpers'
import { CleansetInternalProps, ColumnStatus } from './Cleanset.types'
import { CleansetContext } from './CleansetContext'
import Datasheet from './datasheet/Datasheet'
import { checkNullValueInFilter } from './datasheet/Datasheet.helpers'
import { CLEANLAB_FRONTEND_COLUMN } from './datasheet/Datasheet.types'
import DatasheetNav from './datasheetNav/DatasheetNav'
import { FilterContext, FilterDispatchContext } from './filterReducer/FilterContext'
import { filterReducer } from './filterReducer/FilterReducer'
import { deepCompareFilterData } from './filterReducer/FilterReducer.helpers'
import { initialFilterState } from './filterReducer/InitialFilterState'
import { TabsContext, TabsDispatchContext } from './tabsReducer/TabsContext'
import { tabsReducer } from './tabsReducer/TabsReducer'
import { returnInitialTabsState } from './tabsReducer/TabsState'

const CleansetInternal = (props: CleansetInternalProps) => {
  const queryClient = useQueryClient()
  const { projectDetails, cleansetDetails, allColumns, originalDatasetColumns, columnStatuses } =
    props
  const cleansetId = useParamsCleansetId()
  const [scrollToSummary, setScrollToSummary] = useState(false)
  const [accuracy, setAccuracy] = useState<number | null>(null)
  const [firstGridDataRendered, setFirstGridDataRendered] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)
  const navigate = useNavigate()
  const { showDataLabelingWorkflow } = useCheckHighlyUnlabeledClass({
    cleansetId: cleansetId,
  })

  useEffect(() => {
    if (showDataLabelingWorkflow && !window.location.pathname.includes('/labeling')) {
      navigate(`/cleansets/${cleansetId}/labeling`)
    } else if (!showDataLabelingWorkflow && window.location.pathname.includes('/labeling')) {
      navigate(`/cleansets/${cleansetId}`)
    }
  }, [cleansetId, navigate, showDataLabelingWorkflow])

  const isComplete = useMemo(
    () =>
      Object.keys(columnStatuses).reduce((complete: boolean, columnName: string) => {
        return columnStatuses[columnName] !== ColumnStatus.PENDING && complete
      }, true),
    [columnStatuses]
  )

  const onFilterChange = useCallback(
    (
      property: string,
      filterType: string,
      input: string | number | string[],
      isPreset: boolean
    ) => {
      const isNull = input === '-'
      const filterComponent = gridApi?.getFilterInstance(property)
      filterComponent?.setModel(null)
      if (filterType && filterType !== 'set' && input && columnApi) {
        filterComponent?.setModel({
          type: checkNullValueInFilter(filterType, input),
          filter: input,
        })
        !isPreset && columnApi.setColumnVisible(property, true)
      } else {
        filterComponent?.setModel({
          filterType: isNull ? 'blank' : filterType,
          values: [input],
        })
      }
    },
    [columnApi, gridApi]
  )

  const [filterState, filterDispatch] = useReducer(
    filterReducer,
    initialFilterState(projectDetails.tasktype === Tasktype.MULTILABEL)
  )

  const searchParams = new URLSearchParams(window.location.search)
  const [tabsState, tabsDispatch] = useReducer(
    tabsReducer,
    returnInitialTabsState(searchParams.get('tab'))
  )

  const prevFilterState = useRef(filterState.filterData)

  const { columnState, filterModel } = useRestoreGridSettings(
    cleansetId,
    projectDetails.labelColumn,
    gridApi,
    columnApi,
    filterDispatch,
    showDataLabelingWorkflow
  )

  useEffect(() => {
    if (!deepCompareFilterData(prevFilterState.current, filterState.filterData)) {
      gridApi?.setFilterModel(null)
      filterState.filterData
        .slice()
        .sort((a, b) => (a.applicationOrder ?? 0) - (b.applicationOrder ?? 0))
        .forEach((filter) => {
          onFilterChange(
            filter.property === CLEANLAB_FRONTEND_COLUMN.GIVEN && projectDetails
              ? projectDetails.labelColumn
              : filter.property,
            filter.filterType,
            filter.filterInput,
            filter.isPreset
          )
        })
      gridApi?.onFilterChanged()
      prevFilterState.current = filterState.filterData
    }
  }, [
    filterState.filterData,
    prevFilterState,
    gridApi,
    onFilterChange,
    projectDetails,
    columnApi,
    showDataLabelingWorkflow,
  ])

  const prevColumnStatuses = useRef(columnStatuses)
  const gridContextRef = useRef({ datasheetColumnStatuses: columnStatuses })

  useEffect(() => {
    if (!deepCompareColumnStatuses(prevColumnStatuses.current, columnStatuses)) {
      if (Object.keys(prevColumnStatuses).length > 0) {
        const newReadyColumns = Object.keys(prevColumnStatuses.current).reduce(
          (res: string[], columnName: string) => {
            if (
              prevColumnStatuses.current[columnName] === ColumnStatus.PENDING &&
              columnStatuses[columnName] === ColumnStatus.READY
            ) {
              res.push(columnName)
            }
            return res
          },
          []
        )
        if (newReadyColumns.length > 0) {
          // TODO: smarter query invalidation based on columns that are now ready
          // requires frontend to be aware of what columns are needed for a count query
          queryClient.invalidateQueries(queryKeys.datasheet.id(cleansetId).rowCount().all())
          if (gridApi !== null && columnApi !== null && firstGridDataRendered) {
            setTimeout(() => gridApi.refreshServerSide({}), 500)
          }
        }
      }
      prevColumnStatuses.current = columnStatuses
      gridContextRef.current.datasheetColumnStatuses = columnStatuses
    }
  }, [columnStatuses, queryClient, cleansetId, gridApi, columnApi, firstGridDataRendered])

  return (
    <CleansetContext.Provider
      value={{
        projectId: cleansetDetails.projectId,
        datasetId: cleansetDetails.datasetId,
        cleansetId: cleansetId,
        modality: projectDetails.modality,
        tasktype: projectDetails.tasktype,
        version: cleansetDetails.version,
        columnStatuses: columnStatuses,
        datasetColumns: originalDatasetColumns,
      }}
    >
      <FilterContext.Provider value={filterState}>
        <FilterDispatchContext.Provider value={filterDispatch}>
          <TabsContext.Provider value={tabsState}>
            <TabsDispatchContext.Provider value={tabsDispatch}>
              <Tabs
                variant="enclosed"
                index={tabsState.index}
                onChange={(value: number) => tabsDispatch({ index: value })}
              >
                <Layout
                  nav={
                    showDataLabelingWorkflow ? (
                      <></>
                    ) : (
                      <DatasheetNav
                        projectId={projectDetails.projectId}
                        isMultilabel={projectDetails.tasktype === Tasktype.MULTILABEL}
                        isTemplate={projectDetails.isTemplate}
                      />
                    )
                  }
                >
                  <Box paddingInline="24px">
                    <TabPanels>
                      <TabPanel _focusVisible={{ ring: 'none' }} p={0}>
                        <Datasheet
                          cleansetId={cleansetId}
                          projectDetails={projectDetails}
                          allColumns={allColumns}
                          originalDatasetColumns={originalDatasetColumns}
                          setScrollToSummary={setScrollToSummary}
                          accuracy={accuracy ?? 0}
                          gridApi={gridApi}
                          columnApi={columnApi}
                          setColumnApi={setColumnApi}
                          setGridApi={setGridApi}
                          firstGridDataRendered={firstGridDataRendered}
                          setFirstGridDataRendered={setFirstGridDataRendered}
                          gridContext={gridContextRef}
                          isComplete={isComplete}
                          hasPrevGridState={columnState || filterModel}
                        />
                      </TabPanel>
                      <TabPanel _focusVisible={{ ring: 'none' }}>
                        <Stack width="100%">
                          {projectDetails.tasktype === Tasktype.MULTICLASS &&
                            !showDataLabelingWorkflow && (
                              <CleansetCharts
                                projectName={projectDetails.projectName}
                                scrollToSummary={scrollToSummary}
                                setScrollToSummary={setScrollToSummary}
                                setAccuracy={setAccuracy}
                                isTemplate={projectDetails.isTemplate}
                                firstGridDataRendered={firstGridDataRendered}
                                columns={originalDatasetColumns}
                                labelColumn={projectDetails.labelColumn}
                              />
                            )}
                        </Stack>
                      </TabPanel>
                    </TabPanels>
                  </Box>
                </Layout>
              </Tabs>
            </TabsDispatchContext.Provider>
          </TabsContext.Provider>
        </FilterDispatchContext.Provider>
      </FilterContext.Provider>
    </CleansetContext.Provider>
  )
}

const Cleanset = () => {
  const cleansetId = useParamsCleansetId()
  const toast = useToast()
  const [invalidId, setInvalidId] = useState(false)

  const projectDetails = useCleansetProjectDetails({
    cleansetId,
    onError: (err) => setInvalidIdOrRaiseToast(toast, err, setInvalidId),
  })
  const cleansetDetails = useCleansetDetails({ cleansetId: cleansetId })
  const {
    datasheetColumns,
    datasheetColumnStatuses,
    datasetColumns,
    isLoading: isColumnInfoLoading,
  } = useColumns(cleansetId)
  const [originalDatasetColumns, setOriginalDatasetColumns] = useState<string[] | null>(null)
  const [allColumns, setAllColumns] = useState<string[] | null>(null)

  useEffect(() => {
    if (!isColumnInfoLoading) {
      const datasheetColumnBaseNames = datasheetColumns?.map((v: string) => `_cleanlab_${v}`) ?? []
      setAllColumns(datasheetColumnBaseNames.concat(datasetColumns ?? []))
      setOriginalDatasetColumns(datasetColumns ?? [])
    }
  }, [datasheetColumns, datasetColumns, isColumnInfoLoading])

  if (invalidId || !cleansetId || !isUUID4(cleansetId)) {
    return (
      <Layout>
        <NonExistentResource errorMessage="No such cleanset exists." />
      </Layout>
    )
  }

  if (
    isColumnInfoLoading ||
    !projectDetails ||
    !cleansetDetails ||
    !allColumns ||
    !originalDatasetColumns
  ) {
    return (
      <Layout>
        <Flex width="100%" height="100%" align="center" justify="center">
          <Spinner />
        </Flex>
      </Layout>
    )
  }

  return (
    <CleansetInternal
      projectDetails={projectDetails}
      cleansetDetails={cleansetDetails}
      allColumns={allColumns}
      originalDatasetColumns={originalDatasetColumns}
      columnStatuses={datasheetColumnStatuses}
    />
  )
}

export default Cleanset
