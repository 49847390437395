import { RowCountType } from '@services/datasheet/constants'
import { GridApi } from 'ag-grid-community'
import { ColumnApi } from 'ag-grid-community/dist/lib/columns/columnApi'

export interface FilterSidebarProps {
  cleansetId: string
  gridApi: GridApi
  issueColumns: string[]
  metadataColumns: string[]
  customPresetFilters?: presetFilterType[]
  isCollapsed: boolean
  handleCollapse: () => void
  modality: string
  columnApi: ColumnApi
  firstGridDataRendered: boolean
  presetConfigCallback: (filterType: string) => void
  resetResolverAndRowSelection: () => void
}

export enum ISSUE_TYPE {
  LABEL_ISSUE = 'issue',
  UNLABELED = 'unlabeled',
  AMBIGUOUS = 'ambiguous',
  OUTLIER = 'outlier',
  IS_NEAR_DUPLICATE = 'is_near_duplicate',
}

export enum METADATA_TYPE {
  BEST_EXAMPLES = 'best_examples',
  IS_PII = 'is_PII',
  IS_INFORMAL = 'is_informal',
  IS_NON_ENGLISH = 'is_non_english',
  IS_TOXIC = 'is_toxic',
  IS_BIASED = 'is_biased',
  IS_DARK = 'is_dark',
  IS_LIGHT = 'is_light',
  IS_BLURRY = 'is_blurry',
  IS_ODD_SIZE = 'is_odd_size',
  IS_LOW_INFORMATION = 'is_low_information',
  IS_GRAYSCALE = 'is_grayscale',
  IS_ODD_ASPECT_RATIO = 'is_odd_aspect_ratio',
  IS_NSFW = 'is_NSFW',
}

interface presetFilterType {
  filterType: string
  rowCountType: RowCountType
}

export const ORDERED_PRESET_FILTERS: presetFilterType[] = [
  {
    filterType: RowCountType.TOTAL,
    rowCountType: RowCountType.TOTAL,
  },
  {
    filterType: METADATA_TYPE.BEST_EXAMPLES,
    rowCountType: RowCountType.BEST_EXAMPLES,
  },
  {
    filterType: RowCountType.ISSUES_RESOLVED,
    rowCountType: RowCountType.ISSUES_RESOLVED,
  },
  {
    filterType: RowCountType.LABEL_ISSUES,
    rowCountType: RowCountType.LABEL_ISSUES,
  },
  {
    filterType: ISSUE_TYPE.OUTLIER,
    rowCountType: RowCountType.OUTLIERS,
  },
  {
    filterType: ISSUE_TYPE.UNLABELED,
    rowCountType: RowCountType.UNLABELED,
  },
  {
    filterType: ISSUE_TYPE.AMBIGUOUS,
    rowCountType: RowCountType.AMBIGUOUS,
  },
  {
    filterType: ISSUE_TYPE.IS_NEAR_DUPLICATE,
    rowCountType: RowCountType.NEAR_DUPLICATE,
  },
]

export const ORDERED_METADATA_PRESET_FILTERS: presetFilterType[] = [
  {
    filterType: METADATA_TYPE.IS_PII,
    rowCountType: RowCountType.IS_PII,
  },
  {
    filterType: METADATA_TYPE.IS_INFORMAL,
    rowCountType: RowCountType.IS_INFORMAL,
  },
  {
    filterType: METADATA_TYPE.IS_NON_ENGLISH,
    rowCountType: RowCountType.IS_NON_ENGLISH,
  },
  {
    filterType: METADATA_TYPE.IS_TOXIC,
    rowCountType: RowCountType.IS_TOXIC,
  },
  {
    filterType: METADATA_TYPE.IS_BIASED,
    rowCountType: RowCountType.IS_BIASED,
  },
  {
    filterType: METADATA_TYPE.IS_DARK,
    rowCountType: RowCountType.IS_DARK,
  },
  {
    filterType: METADATA_TYPE.IS_LIGHT,
    rowCountType: RowCountType.IS_LIGHT,
  },
  {
    filterType: METADATA_TYPE.IS_BLURRY,
    rowCountType: RowCountType.IS_BLURRY,
  },
  {
    filterType: METADATA_TYPE.IS_ODD_SIZE,
    rowCountType: RowCountType.IS_ODD_SIZE,
  },
  {
    filterType: METADATA_TYPE.IS_LOW_INFORMATION,
    rowCountType: RowCountType.IS_LOW_INFORMATION,
  },
  {
    filterType: METADATA_TYPE.IS_GRAYSCALE,
    rowCountType: RowCountType.IS_GRAYSCALE,
  },
  {
    filterType: METADATA_TYPE.IS_ODD_ASPECT_RATIO,
    rowCountType: RowCountType.IS_ODD_ASPECT_RATIO,
  },
  {
    filterType: METADATA_TYPE.IS_NSFW,
    rowCountType: RowCountType.IS_NSFW,
  },
]
