import { FormErrorMessage } from '@chakra-ui/react'
import { useBetaFeatureButton } from '@common/misc/betaFeatureButton/useBetaFeatureButton'
import OtherMLTasksModal from '@common/modals/betaFeatureModal/otherMLTasksModal/OtherMLTasksModal'
import { Button } from '@components/button/Button'
import { FormFieldProvider } from '@components/formField/FormFieldContext'
import { LabelRadioCard } from '@components/radioGroup/LabelRadioCard'
import { RadioGroup, RadioGroupItem } from '@components/radioGroup/RadioGroup'
import { Tooltip } from '@components/tooltip/Tooltip'
import { DatasetDetailsProps } from '@services/datasetApi'
import { FormApi, useForm } from '@tanstack/react-form'
import { FormEventHandler, useCallback, useEffect, useId } from 'react'
import { Link, LoaderFunction, redirect, useNavigate, useParams } from 'react-router-dom'

import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { useDatasetDetailsOutletContext } from './DatasetDetailsOutlet'
import {
  Modality,
  modalityChoices,
  Tasktype,
  tasktypeChoices,
} from './projectFormFields/ProjectFormFields.types'
import {
  ProjectWizardStep,
  ProjectWizardStepper,
  useProjectWizardStore,
} from './projectWizard/ProjectWizard.store'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcome,
} from './Sidebar'

type FormValues = { modality: string; taskType: string }
type SelectTypesForm = FormApi<FormValues>

const THIS_STEP = 'selectTypes' as const satisfies ProjectWizardStep

const modalities = Object.entries(modalityChoices)

const checkModality = (value?: string) => {
  if ([Modality.text, Modality.tabular, Modality.image].includes(value as any)) {
    return undefined
  }
  return 'Please select a modality.'
}

export const createProjectSelectTypesLoader: LoaderFunction = async ({ params }) => {
  const { stepMayProceed } = useProjectWizardStore.getState()
  const { datasetId } = params
  if (!datasetId) {
    return redirect('/clean')
  }
  if (!stepMayProceed(THIS_STEP)) {
    return redirect('/clean/dataset')
  }
  return null
}

const CreateProjectModalitySelect = ({
  form,
  datasetDetails,
}: {
  form: SelectTypesForm
  datasetDetails: DatasetDetailsProps
}) => {
  const radioGroupId = useId()
  const otherMLTasksBetaFeature = useBetaFeatureButton('Enterprise ML tasks')

  return (
    <form.Field
      name="modality"
      validators={{
        onSubmit: ({ value }) => checkModality(value),
      }}
    >
      {({ state, name, handleChange, handleBlur }) => {
        return (
          <FormFieldProvider htmlFor={radioGroupId}>
            <RadioGroup
              name={name}
              value={state.value}
              onValueChange={(value) => {
                if (value === Modality.other) {
                  otherMLTasksBetaFeature.onButtonClick()
                  return
                }
                handleChange(value as Modality)
              }}
              onBlur={handleBlur}
              className="grid grid-cols-2 gap-8"
            >
              {modalities.map(([modality, details]) => {
                const textModalityDisabled =
                  modality === Modality.text && datasetDetails.possible_text_columns.length === 0
                const tabularModalityDisabled =
                  modality === Modality.tabular && datasetDetails.feature_columns.length === 0
                const imageModalityDisabled =
                  modality === Modality.image && datasetDetails.image_columns.length === 0
                const currentModalityDisabled =
                  textModalityDisabled || tabularModalityDisabled || imageModalityDisabled

                const content = (
                  <LabelRadioCard
                    key={modality}
                    data-testid={details.dataTestId}
                    disabled={currentModalityDisabled}
                    heading={details.name}
                    description={details.description}
                  >
                    <RadioGroupItem value={modality} />
                  </LabelRadioCard>
                )
                if (currentModalityDisabled) {
                  return (
                    <Tooltip key={modality} content={details.disabledText}>
                      {content}
                    </Tooltip>
                  )
                }
                return content
              })}
            </RadioGroup>
            <OtherMLTasksModal
              {...otherMLTasksBetaFeature}
              onClose={otherMLTasksBetaFeature.onCloseModal}
            />
            {state.meta.errors?.map((error) => {
              return <FormErrorMessage key={error?.toString()}>{error}</FormErrorMessage>
            })}
          </FormFieldProvider>
        )
      }}
    </form.Field>
  )
}

const ClassificationType = ({ form }: { form: SelectTypesForm }) => {
  const radioGroupId = useId()
  return (
    <form.Field name="taskType">
      {({ state: { value, meta }, name, handleBlur, handleChange }) => {
        return (
          <FormFieldProvider htmlFor={radioGroupId} error={meta.errors?.[0]}>
            <RadioGroup
              name={name}
              value={value}
              onValueChange={(value) => handleChange(value as Tasktype)}
              onBlur={handleBlur}
              className="grid grid-cols-2 gap-8"
            >
              {Object.entries(tasktypeChoices).map(([taskType, details]) => {
                return (
                  <LabelRadioCard
                    key={taskType}
                    heading={details.name}
                    description={details.description}
                    data-testid={details.dataTestId}
                  >
                    <RadioGroupItem value={taskType} />
                  </LabelRadioCard>
                )
              })}
            </RadioGroup>
            {meta.errors.map((error) => {
              return <FormErrorMessage key={error?.toString()}>{error}</FormErrorMessage>
            })}
          </FormFieldProvider>
        )
      }}
    </form.Field>
  )
}

const Form = () => {
  const navigate = useNavigate()
  const { datasetId, datasetDetails } = useDatasetDetailsOutletContext()
  const projectName = useProjectWizardStore((state) => state.projectName)
  const setValuesForStep = useProjectWizardStore((state) => state.setValuesForStep)
  const resetStateAfter = useProjectWizardStore((state) => state.resetStateAfter)
  const initialModality =
    useProjectWizardStore((state) => state.modality) ?? datasetDetails.modality
  const initialTaskType = useProjectWizardStore((state) => state.tasktype) ?? Tasktype.MULTICLASS

  const form = useForm<FormValues>({
    defaultValues: {
      modality: initialModality,
      taskType: initialTaskType,
    },
    onSubmit: ({ value }) => {
      if (!value.modality || !value.taskType || !projectName || !datasetId) {
        return
      }
      setValuesForStep(THIS_STEP, {
        tasktype: value.taskType,
        modality: value.modality,
      })
      resetStateAfter('selectTypes')

      navigate(`/clean/${datasetId}/label`)
    },
  })
  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      form.handleSubmit()
    },
    [form]
  )
  const formState = form.useStore((s) => ({
    canSubmit: s.canSubmit,
  }))

  // Update form's defaultValue for taskType when initialTaskType updates
  useEffect(() => {
    form.setFieldValue('taskType', initialTaskType)
  }, [form, initialTaskType])

  return (
    <form className="flex flex-col gap-8" onSubmit={onSubmit}>
      <div className="flex flex-col gap-11">
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Select task type</PageHeading>
            <PageDescription>Choose the AI task for your dataset.</PageDescription>
          </HeadingWrapper>
          <div>
            <ul className="type-body-100 flex flex-wrap gap-3 pb-6 text-text-primary">
              <li>
                <b className="type-body-100-medium">Dataset:</b> {datasetDetails.name}
              </li>
              <li className="text-text-primary">
                <b className="type-body-100-medium">Project name:</b> {projectName}
              </li>
            </ul>
            <CreateProjectModalitySelect form={form} datasetDetails={datasetDetails} />
          </div>
        </CreateProjectContent>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Select a type of classification</PageHeading>
            <PageDescription>
              Choose to allow only one label per datapoint (multi-class) or multiple labels per
              datapoint (multi-label).
            </PageDescription>
          </HeadingWrapper>
          <ClassificationType form={form} />
        </CreateProjectContent>
      </div>
      <ProjectWizardActions step={THIS_STEP}>
        <Button variant="highContrast" size="medium" type="submit" disabled={!formState.canSubmit}>
          Next
        </Button>
      </ProjectWizardActions>
    </form>
  )
}

export const CreateProjectSelectTypes = () => {
  const startPoint = useProjectWizardStore((state) => state.startPoint)
  const datasetId = useParams().datasetId
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          {startPoint === 'datasetId' ? (
            <Link to={`/clean/${datasetId}`}>Name your Project</Link>
          ) : (
            <Link to="/clean/dataset">Select an existing Dataset</Link>
          )}
        </PreviousLink>
        <ProjectWizardStepper step={THIS_STEP} />
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <Form />
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/">Cleanlab Studio docs</ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/data_labeling/">
              Data labeling in Cleanlab Studio
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/multilabel/">
              Multi-label data quality
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}
