import { ColumnTypeV1 } from '@services/datasetApi'

export interface ColumnTypeMenuProps {
  originalColumnType: string
  columnId: string
  editButtonDisabledText: string | null
}
export interface DatasetColumnType {
  columnType?: ColumnTypeV1
  subTypes?: Record<string, ColumnTypeV1>
}

const dateColumnSubTypes: Record<string, ColumnTypeV1> = {
  'Seconds (epoch)': ColumnTypeV1.DateEpoch_s,
  'Milliseconds (epoch)': ColumnTypeV1.DateEpoch_ms,
  'Microseconds (epoch)': ColumnTypeV1.DateEpoch_us,
  'Nanoseconds (epoch)': ColumnTypeV1.DateEpoch_ns,
  'Infer (parse)': ColumnTypeV1.DateParse,
}

const datetimeColumnSubTypes: Record<string, ColumnTypeV1> = {
  'Seconds (epoch)': ColumnTypeV1.DatetimeEpoch_s,
  'Milliseconds (epoch)': ColumnTypeV1.DatetimeEpoch_ms,
  'Microseconds (epoch)': ColumnTypeV1.DatetimeEpoch_us,
  'Nanoseconds (epoch)': ColumnTypeV1.DatetimeEpoch_ns,
  'Infer (parse)': ColumnTypeV1.DatetimeParse,
}

const timeColumnSubTypes: Record<string, ColumnTypeV1> = {
  'Seconds (epoch)': ColumnTypeV1.TimeEpoch_s,
  'Milliseconds (epoch)': ColumnTypeV1.TimeEpoch_ms,
  'Microseconds (epoch)': ColumnTypeV1.TimeEpoch_us,
  'Nanoseconds (epoch)': ColumnTypeV1.TimeEpoch_ns,
  'Infer (parse)': ColumnTypeV1.TimeParse,
}

export const DatasetColumnTypes: Record<string, DatasetColumnType> = {
  Untyped: { columnType: ColumnTypeV1.Untyped },
  Integer: { columnType: ColumnTypeV1.Integer },
  Float: { columnType: ColumnTypeV1.Float },
  Boolean: { columnType: ColumnTypeV1.Boolean },
  String: { columnType: ColumnTypeV1.String },
  Image: { columnType: ColumnTypeV1.ImageExternal },
  Document: { columnType: ColumnTypeV1.DocumentExternal },
  Date: { subTypes: dateColumnSubTypes },
  Datetime: { subTypes: datetimeColumnSubTypes },
  Time: { subTypes: timeColumnSubTypes },
}

export const getColumnTypeDisplayValue = (columnType: string) => {
  return columnType.split('_')[0]
}
