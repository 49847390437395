import { Box, useColorModeValue, useToast, VStack } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import datasetApiService from '@services/datasetApi'
import { MAX_NUM_DEFAULT_COLUMNS } from '@utils/ag-grid/consts'
import { createServerSideDatasource } from '@utils/functions/createServerSideDatasource'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Modality } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import SaveSchemaChangesFloater from '../saveSchemaChangesFloater/SaveSchemaChangesFloater'
import { SchemaUpdatesContext } from '../SchemaUpdatesContext'
import { constructDatasetGridOptions } from './DatasetGridConfig'
import { datasetSideBar, DatasetTableDetailsProps } from './DatasetTableDetails.types'

const DatasetTableDetails = ({
  datasetId,
  datasetDetails,
  fieldTypes,
  refreshData,
  showDataWarnings,
  showDatasetRowsLimit,
  setSchemaUpdateInProgress,
}: DatasetTableDetailsProps) => {
  const [firstDataRendered, setFirstDataRendered] = useState(false)
  const tableColor = useColorModeValue('ag-theme-balham', 'ag-theme-balham-dark')
  const gridOptions: GridOptions = constructDatasetGridOptions(datasetDetails, fieldTypes)
  const toast = useToast()
  const [numRowsPerPage, _setNumRowsPerPage] = useState(50)
  const { schemaUpdates } = useContext(SchemaUpdatesContext)
  const schemaUpdateInProgress = Object.keys(schemaUpdates).length > 0

  const gridReadyEventRef = useRef<GridReadyEvent | null>(null)

  // when grid is initialized, create datasource server and initialize everything
  const handleGridReady = useCallback(
    (event: GridReadyEvent) => {
      gridReadyEventRef.current = event
      const server = datasetApiService.createServer(showDataWarnings)
      const datasource = createServerSideDatasource(
        server,
        datasetId,
        toast,
        () => !firstDataRendered && setFirstDataRendered(true)
      )
      event.api.setServerSideDatasource(datasource)
      event.api.sizeColumnsToFit()
    },
    [showDataWarnings, datasetId, toast, firstDataRendered, setFirstDataRendered]
  )

  useEffect(() => {
    if (gridReadyEventRef.current) {
      handleGridReady(gridReadyEventRef.current)
    }
  }, [handleGridReady, showDataWarnings])

  return (
    <VStack
      align="flex-start"
      display="flex"
      h={showDatasetRowsLimit ? 'calc(78vh - 64px)' : '78vh'}
      w="100%"
    >
      {datasetDetails.columns.length > MAX_NUM_DEFAULT_COLUMNS && (
        <p className="type-body-100 pb-2 text-yellow-800">
          *{MAX_NUM_DEFAULT_COLUMNS} dataset columns are displayed by default. Click the Columns
          button on the left side of the table to display more columns.
        </p>
      )}
      <Box className={tableColor + ' cleanset-tour-datasheet'} flex={1} w="100%">
        <CleanlabGrid
          gridOptions={gridOptions}
          onGridReady={handleGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          getRowId={({ data }) => data[datasetDetails.id_column]}
          headerHeight={50}
          rowBuffer={datasetDetails.modality === Modality.image ? 100 : 10}
          tooltipShowDelay={0}
          sideBar={schemaUpdateInProgress ? null : datasetSideBar}
        />
        {schemaUpdateInProgress && (
          <SaveSchemaChangesFloater
            datasetId={datasetId}
            refreshData={refreshData}
            setSchemaUpdateInProgress={setSchemaUpdateInProgress}
          />
        )}
      </Box>
    </VStack>
  )
}

export default DatasetTableDetails
