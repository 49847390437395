import {
  Grid,
  GridItem,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import React from 'react'

import { ProjectDetailsModalProps } from './ProjectDetailsModal.types'

const ProjectDetailsModal = (props: ProjectDetailsModalProps) => {
  const { isOpen, onClose, rowData } = props
  return (
    rowData && (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Project: {rowData.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem rowSpan={1} colSpan={1}>
                <VStack align="flex-start">
                  <Text fontWeight={600} fontSize="md">
                    Modality
                  </Text>
                  <Text fontSize="md">{rowData.modality}</Text>
                </VStack>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <VStack align="flex-start">
                  <Text fontWeight={600} fontSize="md">
                    Model type
                  </Text>
                  <Text fontSize="md">{rowData.model_type}</Text>
                </VStack>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <VStack align="flex-start">
                  <Text fontWeight={600} fontSize="md">
                    ID column
                  </Text>
                  <Text fontSize="md">{rowData.id_column}</Text>
                </VStack>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <VStack align="flex-start">
                  <Text fontWeight={600} fontSize="md">
                    Label column
                  </Text>
                  <Text fontSize="md">{rowData.label_column}</Text>
                </VStack>
              </GridItem>
            </Grid>
            <VStack align="flex-start" mt={6}>
              <Text fontWeight={600} fontSize="md">
                Predictive columns
              </Text>
              <VStack maxHeight="25vh" overflowY="auto" width="100%" align="flex-start">
                <UnorderedList>
                  {rowData.predictive_columns.map((c) => (
                    <ListItem key={c} fontSize="md">
                      {c}
                    </ListItem>
                  ))}
                </UnorderedList>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    )
  )
}

export default ProjectDetailsModal
