import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { losslessNumberParser } from '@utils/functions/losslessNumberParser'
import axios from 'axios'
import { FilePondFile } from 'filepond'
import { queryClient } from 'src/queryClient'

import { deploymentsQueryKeys } from './deployments/constants'

export const deploymentApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/v1/deployment`,
  withCredentials: true,
  transformResponse: (res) => losslessNumberParser(res),
})

export const deploymentsQueryFn = async (): Promise<any[]> => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.get('/', createAuthHeaders(accessToken))
  return response.data
}

export const deploymentsQueryOptions = () =>
  ({
    queryKey: deploymentsQueryKeys.deployments.all(),
    queryFn: deploymentsQueryFn,
  }) as const

const getDeployments = async (): Promise<DeploymentRowProps[]> => {
  try {
    return await queryClient.fetchQuery(deploymentsQueryOptions())
  } catch {
    return []
  }
}

const getDeployment = async (deploymentId: string) => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.get(`/${deploymentId}`, createAuthHeaders(accessToken))
  return response.data
}

const createDeployment = async (
  deploymentName: string,
  cleansetId: string,
  datasetId: string,
  projectId: string
) => {
  const accessToken = await auth.getTokenSilently()
  const body = {
    deployment_name: deploymentName,
    cleanset_id: cleansetId,
    dataset_id: datasetId,
    project_id: projectId,
  }
  const response = await deploymentApi.post('/', body, createAuthHeaders(accessToken))
  queryClient.invalidateQueries(deploymentsQueryKeys.deployments.all())
  return response.data
}

const deleteDeployment = async (deploymentId: string) => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.delete(`/${deploymentId}`, createAuthHeaders(accessToken))
  queryClient.invalidateQueries(deploymentsQueryKeys.deployments.all())
  return response.data
}

const deleteQuery = async (queryId: string) => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.delete(`/query/${queryId}`, createAuthHeaders(accessToken))
  return response.data
}

const createQuery = async (deploymentId: string, file: FilePondFile) => {
  const formData = new FormData()
  formData.append('file', file.file)
  formData.append('query_type', 'BATCH')
  formData.append('data_source', 'FILE_IN_REQUEST_SOURCE')
  const accessToken = await auth.getTokenSilently()
  const body = formData
  const response = await deploymentApi.post(
    `/${deploymentId}/query`,
    body,
    createAuthHeaders(accessToken)
  )
  return response.data
}

const getModelSummary = async (
  deploymentId: string,
  cleansetId: string
): Promise<ModelSummaryRowProps[]> => {
  const accessToken = await auth.getTokenSilently()
  const body = {
    cleanset_id: cleansetId,
  }
  const response = await deploymentApi.post(
    `/${deploymentId}/model_summary`,
    body,
    createAuthHeaders(accessToken)
  )

  return response.data
}

const queryHistoryForDeployment = async (deploymentId: string) => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.get(
    `/${deploymentId}/query_history`,
    createAuthHeaders(accessToken)
  )
  return response.data
}

const exportQueryResults = async (deploymentId: string, queryId: string) => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.get(
    `/${deploymentId}/query/${queryId}`,
    createAuthHeaders(accessToken)
  )
  return response.data
}

const pollQuery = async (deploymentId: string, queryId: string) => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.get(
    `/${deploymentId}/query/${queryId}`,
    createAuthHeaders(accessToken)
  )
  return response.data
}

export interface DeploymentRowProps {
  id: string
  name: string
  cleanset_id: string
  dataset_id: string
  dataset_name: string
  project_id: string
  updated_at: string
  status: string
  tasktype: string
}

export interface ExportModelRowProps {
  date: string
  dataset: string
  evaluationResults: string
  status: string
  query_id: string
}

export interface ModelSummaryRowProps {
  'Held-out Accuracy': number
  Model: string
  'Used for Inference': boolean
}

const apiMethods = {
  getDeployments,
  createDeployment,
  getDeployment,
  createQuery,
  deleteDeployment,
  deleteQuery,
  queryHistoryForDeployment,
  exportQueryResults,
  pollQuery,
  getModelSummary,
}

export default apiMethods
