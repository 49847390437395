import SupportModal from '@common/layout/supportModal/SupportModal'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import React, { ReactNode, useState } from 'react'
import { InlineLink } from 'src/pages/projectForm/Sidebar'

export const ContactSupportLink = ({ children }: { children: ReactNode }) => {
  const { trackEvent } = useEventTracking()
  const [supportModalOpen, setSupportModalOpen] = useState(false)
  return (
    <>
      <InlineLink
        to="#"
        onClick={(e) => {
          e.preventDefault()
          trackEvent(MixpanelEvents.clickSupport)
          setSupportModalOpen(true)
        }}
      >
        {children}
      </InlineLink>
      <SupportModal isOpen={supportModalOpen} onClose={() => setSupportModalOpen(false)} />
    </>
  )
}

export const ERROR_DESCRIPTIONS = {
  handledErrorPartOne: 'We encountered an issue while processing your Dataset.',
  handledErrorPartTwo:
    'See the detailed error message below for guidance on how you might be able to fix your Dataset.',
  handledErrorPartThree: (
    <>
      Supporting arbitrary Datasets is tricky. If you're still having trouble uploading data, please{' '}
      <ContactSupportLink>contact support</ContactSupportLink>
    </>
  ),
  unhandledErrorPartOne:
    'We experienced an internal error while processing your Dataset. Supporting arbitrary Datasets is tricky, and we’re working to add support for more corner cases. Our engineers have been alerted and are working on a fix. Click ',
  unhandledErrorPartTwo:
    ' to be notified by email once the issue is fixed. Thank you for your patience!',
  unhandledErrorPartThree:
    'If you would like to see a detailed error message that might help you work around the issue, you can click to reveal it below.',
  unhandledErrorVpc:
    'We experienced an internal error while processing your Dataset. Supporting arbitrary Datasets is tricky, and we’re working to add support for more corner cases. If this issue persists, please contact your organization administrator.',
} as const

export const PROCESSING_TEXT = {
  p1: 'Cleanlab Studio is working on processing your data.',
  p2: 'This step may take some time. You can close this page and return to it later to review the results and proceed to create a Project.',
  emailToastText: 'You’ll receive an email when your Dataset is ingested.',
  emailButtonText: 'Notify me',
  emailButtonTooltip: 'Click to receive an email when your Dataset is ingested.',
  emailButtonTooltipDisabled: 'You’ll receive an email when your Dataset is ingested.',
} as const
