import { CLEANLAB_FRONTEND_COLUMN } from '../datasheet/Datasheet.types'
import { FilterData, FilterOperator, FilterState, PresetHeaders } from './FilterState.types'

const presetFilters: FilterData[] = [
  {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Given,
    agGridType: '',
    isOpen: false,
    isPreset: false,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Suggested,
    agGridType: '',
    isOpen: false,
    isPreset: false,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.ISSUE,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Issue,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.OUTLIER,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Outlier,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.UNLABELED,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Unlabeled,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Ambiguous,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.NearDuplicate,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.ISSUE_TAGS,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Issues,
    agGridType: '',
    isOpen: false,
    isPreset: false,
  },
]

const presetMultiLabelFeatures = presetFilters.map((filter) => {
  if (
    filter.property === CLEANLAB_FRONTEND_COLUMN.GIVEN ||
    filter.property === CLEANLAB_FRONTEND_COLUMN.SUGGESTED
  ) {
    return { ...filter, filterInput: [] }
  }
  return filter
})

export const getPresetFilters = (isMultilabel: boolean): FilterData[] => {
  return isMultilabel ? presetMultiLabelFeatures : presetFilters
}

export const initialFilterState = (isMultilabel: boolean): FilterState => ({
  filterData: getPresetFilters(isMultilabel),
  addFilterMenuOpen: false,
  isMultilabel: isMultilabel,
})
