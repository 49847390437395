export const queryKeys = {
  projects: {
    all: () => ['projects'] as const,
    id: (projectId: string) => ({
      all: () => [...queryKeys.projects.all(), projectId],
      templateStatus: () => [...queryKeys.projects.id(projectId).all(), 'templateStatus'],
    }),
    enabledModelTypes: (datasetId: string) => [
      ...queryKeys.projects.all(),
      'enabledModelTypes',
      datasetId,
    ],
    allV2: (props?: any) => [...queryKeys.projects.all(), 'v2', ...(props ? [props] : [])] as const,
  },
}

export interface ProjectRes {
  dataset_id: string
  deleted_at?: number
  exported_at?: number
  has_multiple_cleansets?: boolean
  id: string
  id_column: string
  is_exported: boolean
  is_template: boolean
  label_column: string
  labels: string[]
  last_updated: number
  modality: string
  model_type: string
  name: string
  paid_export: boolean
  predictive_columns: string[]
  status: '0'
  tasktype: string
  unused_columns: string[]
  variable_columns: string[]
}

type BasicProjectRowRes = {
  id: string
  name: string
  dataset_id: string
  dataset_name: string
  tasktype: string
  modality: string
  model_type: string
  id_column: string
  label_column: string
  labels: string[]
  predictive_columns: string[]
  possible_numeric_columns: string[]
  possible_text_columns: string[]
  datetime_columns: string[]
  identifier_columns: string[]
  unused_columns: string[]
  variable_columns: string[]
  last_updated: number
  latest_cleanset_id: string
  is_template: boolean
  progress_description: string
  is_ready: boolean
  is_not_viewable_in_ui: boolean
  has_error: boolean
  error_message?: string
  error_type?: string
  has_multiple_cleansets?: boolean
  current_training_stage: number
  num_issues?: number
  num_issues_resolved?: number
  num_resolved?: number
  num_total?: number
  required_columns_ready: boolean
  isEnrichment?: false
}

export interface EnrichmentProjectRowRes extends Omit<BasicProjectRowRes, 'isEnrichment'> {
  isEnrichment: true
  status: string
}

export type ProjectsRes = { row_count: number; rows: ProjectRowRes[] }
export type ProjectRowRes = EnrichmentProjectRowRes | BasicProjectRowRes

export type EnabledModelTypesRes = { [modality: string]: string[] }
