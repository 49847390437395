import { useToast } from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import logger from '@utils/logger'
import { AnyObject } from '@utils/types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import { PlanData, queryKeys, SubscriptionData } from './constants'

const apiVersion = 'v1'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/billing`,
  withCredentials: true,
})

const datasheetFileApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasheets`,
  withCredentials: true,
})

export const useAddPlan = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (planData: PlanData) => {
      const accessToken = await auth.getTokenSilently()
      const body = { ...planData }
      await axiosClient.post('/plans', body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.billing.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useEditPlan = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({ planId, planData }: { planId: string; planData: PlanData }) => {
      const accessToken = await auth.getTokenSilently()
      const body = { ...planData }
      await axiosClient.patch(`/billing-plan/${planId}`, body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.billing.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useDeletePlan = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (planId: string) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.delete(`/billing-plan/${planId}`, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.billing.all() })
      toast({
        ...defaultToastProps,
        status: 'success',
        description: 'Deleted plan',
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useEditSubscription = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({
      subscriptionId,
      subscriptionData,
    }: {
      subscriptionId: string
      subscriptionData: SubscriptionData | PlanData
    }) => {
      const accessToken = await auth.getTokenSilently()
      const body = { ...subscriptionData }
      await axiosClient.patch(
        `/billing-subscription/${subscriptionId}`,
        body,
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.billing.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useConfirmSubscriptionUpgrade = ({ onSuccess }: { onSuccess?: VoidFunction }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const body = {}
      await axiosClient.post('/confirm-subscription-upgrade', body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.billing.all())
      onSuccess?.()
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useConfirmSubscriptionCancel = ({ onSuccess }: { onSuccess?: VoidFunction }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const body = {}
      await axiosClient.patch(
        '/confirm-subscription-cancellation',
        body,
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.billing.all())
      onSuccess?.()
      toast({
        ...defaultToastProps,
        status: 'success',
        description: 'Subscription canceled successfully',
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useExportCleanset = ({
  cleansetId,
  exportSetting,
  filterModel,
  displayedColumns,
  projectId,
  onSuccess,
  onError,
}: {
  cleansetId: string
  exportSetting: string
  filterModel: { [key: string]: string }
  displayedColumns: string[]
  projectId: string
  onSuccess?: (res: AxiosResponse) => void
  onError?: (err: AxiosError) => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const body: AnyObject = {
        export_setting: exportSetting,
      }

      if (exportSetting === 'custom') {
        logger.info('filter model is', filterModel)
        body.filter = filterModel ? filterModel : null
        body.displayed_columns = displayedColumns
      }
      return await datasheetFileApi.post(`/export/${cleansetId}`, body, {
        ...createAuthHeaders(accessToken),
        responseType: 'arraybuffer',
      })
    },
    onSuccess: (res) => {
      void queryClient.invalidateQueries(queryKeys.billing.cleansetExportDetails(projectId))
      onSuccess?.(res)
    },
    onError: (err) => {
      onError?.(err as AxiosError)
    },
  })
}

export const useSetupPaymentMethod = ({
  onSuccess,
}: {
  onSuccess: (res: AxiosResponse) => void
}) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const body = {}
      const response = await axiosClient.post(
        '/create-setup-intent',
        body,
        createAuthHeaders(accessToken)
      )
      return response
    },
    onSuccess: (res) => {
      onSuccess(res)
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}
