import { enrichmentProjectsQueryOptions } from '@services/enrichmentApi'
import { UseQueryOptionsPassThrough } from '@services/useQuery.helpers'
import { ENRICHMENT_ENABLED } from '@utils/environmentVariables'
import { useQuery } from 'react-query'

import { EnrichmentProjectsRes } from './constants'

export const useEnrichmentProjects = ({
  page = 1,
  useQueryOptions,
}: {
  page?: number
  useQueryOptions?: UseQueryOptionsPassThrough<EnrichmentProjectsRes>
}) => {
  const { meta = {}, enabled, ...options } = useQueryOptions ?? {}
  return useQuery<EnrichmentProjectsRes>({
    ...enrichmentProjectsQueryOptions({ page }),
    meta: { error: 'Failed to fetch enrichment Projects.', ...meta },
    enabled: ENRICHMENT_ENABLED && enabled,
    ...options,
  })
}
