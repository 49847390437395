import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import deploymentApiService from '@services/deploymentApi'
import { queryKeys } from '@services/userQuota/constants'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { AxiosError } from 'axios'
import pluralize from 'pluralize'
import { FormEvent, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { CleansetContext } from '../CleansetContext'
import { DeployModelModalProps } from './DeployModelModal.types'

const MAX_CHARACTER_COUNT = 100
const MODEL_NAME_SUFFIX = ' Model'

const DeployModelModal = (props: DeployModelModalProps) => {
  const { isOpen, onClose, cleansetId, datasetId, projectId, projectName, accuracy, isTemplate } =
    props
  const [buttonLoading, setButtonLoading] = useState(false)
  const [deploymentName, setDeploymentName] = useState(
    `${projectName.slice(0, MAX_CHARACTER_COUNT - MODEL_NAME_SUFFIX.length)}${MODEL_NAME_SUFFIX}`
  )
  const toast = useToast()
  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()
  const { freeTrialPeriod, numModelsAvailable } = useSubscription()
  const queryClient = useQueryClient()

  const warningColor = useColorModeValue('orange.500', 'orange.300')
  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  const navigate = useNavigate()

  const handleDeployFormSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setButtonLoading(true)
    trackEvent(MixpanelEvents.clickModalDeployModelButton, {
      ...cleansetInfo,
      modelName: deploymentName,
      isTemplate: isTemplate,
    })
    try {
      await deploymentApiService.createDeployment(deploymentName, cleansetId, datasetId, projectId)
      navigate('/', { state: { scrollToDeployments: true } })
      toast({
        ...defaultToastProps,
        description: 'Your model has started deployment.',
        status: 'success',
      })
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError, { title: 'Model deployment failed' })
    } finally {
      queryClient.invalidateQueries({ queryKey: queryKeys.quotas.self() })
      onClose()
      setButtonLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Train Improved Model</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md" px={6} pb={6} pt={0}>
          <VStack align="left">
            <Text>
              Cleanlab Studio trains and deploys a new model using state-of-the-art AutoML on your
              improved Dataset, taking into account your corrections and exclusions. You can then
              use the deployed model to infer predictions about new data.
            </Text>
            {accuracy && (
              <Text>
                Accuracy of ML model on your original Dataset: {Math.floor(accuracy * 100) / 100}%
              </Text>
            )}
            {accuracy && (
              <Text>Accuracy of ML model on your improved Dataset: (run to find out)</Text>
            )}
          </VStack>
          <VStack as="form" onSubmit={handleDeployFormSubmit}>
            <FormControl pt={4}>
              <FormLabel>Model Name</FormLabel>
              <Input
                type="name"
                autoFocus
                value={deploymentName}
                placeholder="Enter model name"
                maxLength={MAX_CHARACTER_COUNT}
                onKeyDown={(e) => {
                  // prevent command palette options from capturing event
                  e.stopPropagation()
                }}
                onChange={(e) => setDeploymentName(e.target.value)}
              />
              <Flex justify="right">
                <FormHelperText>
                  {MAX_CHARACTER_COUNT - deploymentName.length} characters left
                </FormHelperText>
              </Flex>
              {IS_VPC_ENVIRONMENT ? null : numModelsAvailable > 0 ? (
                <Text color={warningColor} pt={4} fontSize="sm">
                  {numModelsAvailable} model {pluralize('deployment', numModelsAvailable)} remaining
                  on your {freeTrialPeriod ? 'free trial' : 'plan'}.
                </Text>
              ) : (
                <Text color="red" pt={4} fontSize="sm">
                  No model deployments remaining on your {freeTrialPeriod ? 'free trial' : 'plan'},
                  email{' '}
                  <Link href="mailto:sales@cleanlab.ai" isExternal>
                    sales@cleanlab.ai
                  </Link>{' '}
                  to deploy more models.
                </Text>
              )}
            </FormControl>
            <Flex w="100%" pt={6} justify="flex-end">
              <PrimaryButton
                type="submit"
                isDisabled={deploymentName === '' || !numModelsAvailable}
                isLoading={buttonLoading}
                height="36px"
              >
                Train Improved Model
              </PrimaryButton>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DeployModelModal
