import TopNavTabs from '@common/layout/topNavTabs/TopNavTabs'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useContext } from 'react'

import { CleansetContext } from '../../CleansetContext'
import { CLEANSET_TABS } from '../../tabsReducer/TabsState'
import { DatasheetNavTabsProps } from './DatasheetNavTabs.types'

const DatasheetNavTabs = (props: DatasheetNavTabsProps) => {
  const { tabIndex, isMultilabel, isTemplate } = props

  const tabNames = isMultilabel
    ? CLEANSET_TABS.filter((tabName) => tabName !== 'Analytics')
    : CLEANSET_TABS

  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()

  return (
    <TopNavTabs
      additionalProps={[{}, { className: 'cleanset-tour-analytics-button' }]}
      clickHandlers={[
        () =>
          trackEvent(MixpanelEvents.clickCleansetTab, { ...cleansetInfo, isTemplate: isTemplate }),
        () =>
          trackEvent(MixpanelEvents.clickAnalyticsTab, { ...cleansetInfo, isTemplate: isTemplate }),
      ]}
      tabNames={tabNames}
      tabIndex={tabIndex}
    />
  )
}

export default DatasheetNavTabs
