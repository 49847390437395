import { Box, Button, Flex, HStack, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import { useAuth } from '@hooks/useAuth'
import useGridClassname from '@hooks/useGridClassname'
import { PlanData, SubscriptionData } from '@services/billing/constants'
import { useEditSubscription } from '@services/billing/mutations'
import { useGetBillingSubscriptions } from '@services/billing/queries'
import { GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community'
import React, { useState } from 'react'

import EditSubscriptionModal from './editSubscriptionModal/EditSubscriptionModal'
import { subscriptionGridOptions } from './SubscriptionGrid.helpers'
import SwitchPlansModal from './switchPlansModal/SwitchPlansModal'

const SubscriptionGrid = () => {
  const numRowsPerPage = 20
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [selectedRowData, setSelectedRowData] = useState<any>(null)

  const {
    isOpen: isSwitchPlansOpen,
    onOpen: onSwitchPlansOpen,
    onClose: onSwitchPlansClose,
  } = useDisclosure()
  const {
    isOpen: isEditSubscriptionOpen,
    onOpen: onEditSubscriptionOpen,
    onClose: onEditSubscriptionClose,
  } = useDisclosure()

  const { mutate: editSubscription } = useEditSubscription()

  const { isAuthenticated } = useAuth()

  const toast = useToast()

  const { rows } = useGetBillingSubscriptions(isAuthenticated)

  const handleEditSubscriptionSubmit = (
    subscriptionId: string,
    subscriptionData: SubscriptionData | PlanData // can be PlanData in the case of Switching Plans
  ) => {
    editSubscription({ subscriptionId, subscriptionData })
  }

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
  }

  return (
    <Stack>
      <Flex justify="flex-end">
        <HStack>
          <Button
            colorScheme="orange"
            size="sm"
            onClick={() => {
              if (gridApi && gridApi.getSelectedRows().length) {
                onEditSubscriptionOpen()
              } else {
                toast({
                  description: 'No subscription selected',
                })
              }
            }}
          >
            Edit Subscription
          </Button>
          <Button
            colorScheme="linkedin"
            size="sm"
            onClick={() => {
              if (gridApi && gridApi.getSelectedRows().length) {
                onSwitchPlansOpen()
              } else {
                toast({
                  description: 'No subscription selected',
                })
              }
            }}
          >
            Switch Plan
          </Button>
        </HStack>
      </Flex>
      <Box className={useGridClassname()} h="70vh" w="100%">
        <CleanlabGrid
          gridOptions={subscriptionGridOptions}
          onGridReady={handleGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={rows}
          onRowSelected={(event: RowSelectedEvent) =>
            event.node.isSelected() && setSelectedRowData(event.data)
          }
        />
      </Box>
      {gridApi && selectedRowData && (
        <EditSubscriptionModal
          isOpen={isEditSubscriptionOpen}
          onClose={onEditSubscriptionClose}
          onSubmit={handleEditSubscriptionSubmit}
          rowData={selectedRowData}
        />
      )}
      {gridApi && selectedRowData && (
        <SwitchPlansModal
          isOpen={isSwitchPlansOpen}
          onClose={onSwitchPlansClose}
          onSubmit={handleEditSubscriptionSubmit}
          currentPlan={`${selectedRowData.plan_name}, ${selectedRowData?.tier}`}
          currentSubscriptionId={selectedRowData.id}
          currentPlanId={selectedRowData.plan_id}
        />
      )}
    </Stack>
  )
}

export default SubscriptionGrid
