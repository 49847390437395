import { EnrichmentProjectRowRes } from '@services/project/constants'

export const queryKeys = {
  enrichmentProjects: {
    all: (props?: { page: number }) => ['enrichmentProjects', ...[props ? [props] : []]] as const,
    id: (id: string) => ({
      all: () => [...queryKeys.enrichmentProjects.all(), id],
    }),
  },
}

export type EnrichmentProjectsRes = {
  projects: EnrichmentProjectRowRes[]
  total_count: number
}
