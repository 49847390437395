import { useDisclosure, useInterval, useToast } from '@chakra-ui/react'
import { SimpleLoading } from '@common/layout/loading/Loading'
import { GridLoadingMessage } from '@common/misc/gridLoadingMessage/GridLoadingMessage'
import DeleteRowModal from '@common/modals/deleteRowModal/DeleteRowModal'
import { Button } from '@components/button/Button'
import { InputSearch } from '@components/input/Input'
import { useDashboardFilter } from '@hooks/useDashboardFilter'
import useIsTabActive from '@hooks/useIsTabActive'
import { useKeyPress } from '@hooks/useKeyPress'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { useDeleteEnrichmentProjectMutation } from '@services/enrichment/mutations'
import enrichmentProjectApiService from '@services/enrichmentApi'
import { ProjectRowRes } from '@services/project/constants'
import { useDeleteProjectMutation, useUpdateProjectNameMutation } from '@services/project/mutations'
import projectApiService from '@services/projectApi'
import { handlePaginationGridPageChanged } from '@utils/functions/handlePaginationGridPageChange'
import logger from '@utils/logger'
import { cn } from '@utils/tailwindUtils'
import {
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  IRowNode,
  PaginationChangedEvent,
  RowModelType,
} from 'ag-grid-community'
import { GridReadyEvent, ModelUpdatedEvent } from 'ag-grid-community/dist/lib/events'
import { AxiosError } from 'axios'
import { memo, Suspense, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import testIds from 'src/playwright/testIds'

import { DASHBOARD_DEFAULT_COLUMN_DEF, DashboardGrid } from '../DashboardGrid'
import NoResultsOverlay from '../noResultsOverlay/NoResultsOverlay'
import NonViewableProjectModal from './nonViewableProjectModal/NonViewableProjectModal'
import ProjectDetailsModal from './projectDetailsModal/ProjectDetailsModal'
import ProjectErrorModal from './projectErrorModal/ProjectErrorModal'
import { getColumnDefs } from './ProjectsGrid.helpers'

export const NUM_PROJECTS_PER_PAGE = 20

const CreateProjectButton = memo(() => {
  return (
    <Button className="shrink-0" variant="primaryFaint" asChild>
      <Link to="/clean">Create Project</Link>
    </Button>
  )
})

const ProjectsGrid = ({
  isEnrichment,
  className = '',
}: {
  isEnrichment?: boolean
  className?: string
}) => {
  const [dataRendered, setDataRendered] = useState(false)
  const [isFetchingRows, setIsFetchingRows] = useState(false)
  const [isVisible, setIsVisible] = useState(!isEnrichment)
  const {
    isOpen: isDetailsModalOpen,
    onOpen: onDetailsModalOpen,
    onClose: onDetailsModalClose,
  } = useDisclosure()
  const {
    isOpen: isEnrichmentProjectModalOpen,
    onOpen: onEnrichmentProjectModalOpen,
    onClose: onEnrichmentProjectModalClose,
  } = useDisclosure()
  const {
    isOpen: isNonViewableProjectModalOpen,
    onOpen: onNonViewableProjectModalOpen,
    onClose: onNonViewableProjectModalClose,
  } = useDisclosure()
  const isTabVisible = useIsTabActive()

  const { mutate: updateProject } = useUpdateProjectNameMutation(
    () => gridApi && gridApi.refreshServerSide()
  )
  const { mutate: deleteProject } = (
    isEnrichment ? useDeleteEnrichmentProjectMutation : useDeleteProjectMutation
  )(() => gridApi && gridApi.refreshServerSide())

  // project errors modal
  const {
    isOpen: isProjectErrorModalOpen,
    onOpen: onProjectErrorModalOpen,
    onClose: onProjectErrorModalClose,
  } = useDisclosure()

  // delete modal
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const [targetRowData, setTargetRowData] = useState<ProjectRowRes | null | undefined>()

  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const toast = useToast()

  useKeyPress({
    callback: () => {
      if (gridApi?.getEditingCells().length) {
        gridApi.stopEditing()
      }
    },
    preventDefault: false,
    stopPropagation: false,
    keys: ['Enter'],
  })

  const handleGridReady = (event: GridReadyEvent) => {
    const server = (isEnrichment ? enrichmentProjectApiService : projectApiService).createServer()
    const datasource = (
      isEnrichment ? enrichmentProjectApiService : projectApiService
    ).createServerSideDatasource(
      server,
      toast,
      () => !dataRendered && setDataRendered(true),
      setIsFetchingRows,
      isEnrichment
    )
    setGridApi(event.api)
    event.api.setServerSideDatasource(datasource)
    event.api.sizeColumnsToFit()
  }

  const columnDefs = useMemo(
    () =>
      getColumnDefs({
        handleNameChange: (evt) => {
          if (isEnrichment) {
            // updateEnrichmentProject(evt.data)
          } else {
            updateProject(evt.data)
          }
        },
        actionsCellParams: {
          setTargetRowData,
          onDetailsModalOpen,
          onDeleteOpen,
          onNonViewableProjectModalOpen,
          onEnrichmentProjectModalOpen,
        },
        statusCellParams: {
          setTargetRowData,
          onNonViewableProjectModalOpen,
          onProjectErrorModalOpen,
        },
        isEnrichment,
      }),
    [
      isEnrichment,
      onDeleteOpen,
      onDetailsModalOpen,
      onNonViewableProjectModalOpen,
      onProjectErrorModalOpen,
      updateProject,
      onEnrichmentProjectModalOpen,
    ]
  )

  const gridOptions = useMemo(
    () => ({
      rowHeight: 45,
      columnDefs: columnDefs,
      defaultColDef: {
        ...DASHBOARD_DEFAULT_COLUMN_DEF,
      } as const satisfies ColDef,
      animateRows: true,
      getContextMenuItems: (params: GetContextMenuItemsParams) => {
        if (params.value === undefined) {
          return []
        } else {
          return ['copy']
        }
      },
      suppressMultiSort: true,
      rowModelType: 'serverSide' as RowModelType,
      suppressServerSideInfiniteScroll: false,
      cacheBlockSize: 20, // fetch this number of rows at a time
      maxBlocksInCache: 1, // only keep 1 block of rows in cache
    }),
    [columnDefs]
  )

  usePollProjectReadiness(gridApi, dataRendered, isFetchingRows, isTabVisible)
  const {
    isGridReady,
    onFilterInputChange,
    updateEmptyState,
    noRowsOverlayComponentParams,
    filterValue,
  } = useDashboardFilter({
    gridApi,
    debounceWait: 250,
    runImmediately: false,
    gridType: 'project',
  })

  const checkIfSectionShouldBeHidden = useCallback(({ api }: ModelUpdatedEvent) => {
    // Wrapped in a setTimeout to prevent the grid from flickering in and out
    // since ag-grid's getDisplayedRowCount returns`1` while API is fetching...
    setTimeout(() => {
      const displayedRowCount = api?.getDisplayedRowCount()

      if (displayedRowCount === 0) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }, 250)
  }, [])

  return (
    <Suspense fallback={<SimpleLoading />}>
      <div
        className={cn(
          'home-tour-projects-table hidden',
          isEnrichment ? 'h-[35vh] min-h-[350px]' : 'h-[40vh] min-h-[400px]',
          isVisible && 'block',
          className
        )}
      >
        <div
          className="flex h-full w-full flex-col gap-5"
          data-testid={testIds.dashboardPageProjectsGrid}
        >
          <div className="flex w-full items-end justify-between">
            <h2 className="type-display-50 text-text-strong">
              {isEnrichment ? 'Enrichment ' : ''}Projects
            </h2>
          </div>
          {targetRowData && (
            <DeleteRowModal
              isOpen={isDeleteOpen}
              onClose={onDeleteClose}
              rowData={targetRowData}
              handleDeleteButtonClicked={(rowData) => deleteProject(rowData.id)}
              deletionType="project"
            />
          )}
          <div className="flex w-full items-start gap-5">
            {!isEnrichment && (
              <>
                <InputSearch
                  disabled={!isGridReady}
                  placeholder="Filter by Project name"
                  value={filterValue}
                  onChange={onFilterInputChange}
                />
                <CreateProjectButton />
              </>
            )}
          </div>
          <DashboardGrid<ProjectRowRes>
            gridOptions={gridOptions}
            pagination
            paginationPageSize={NUM_PROJECTS_PER_PAGE}
            onGridReady={handleGridReady}
            loadingOverlayComponent={GridLoadingMessage}
            onModelUpdated={isEnrichment ? checkIfSectionShouldBeHidden : updateEmptyState}
            noRowsOverlayComponent={NoResultsOverlay}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            suppressScrollOnNewData={true}
            onPaginationChanged={(evt: PaginationChangedEvent) => {
              handlePaginationGridPageChanged(evt)
            }}
            getRowId={({ data }) => data.id}
          />
          {targetRowData && isDetailsModalOpen && (
            <ProjectDetailsModal
              isOpen={isDetailsModalOpen}
              onClose={onDetailsModalClose}
              rowData={targetRowData}
            />
          )}
          {targetRowData && isNonViewableProjectModalOpen && (
            <NonViewableProjectModal
              isOpen={isNonViewableProjectModalOpen}
              onClose={onNonViewableProjectModalClose}
              tasktype={targetRowData.tasktype}
            />
          )}
          {targetRowData && isProjectErrorModalOpen && (
            <ProjectErrorModal
              isOpen={isProjectErrorModalOpen}
              onClose={onProjectErrorModalClose}
              projectDetails={targetRowData}
            />
          )}
          {isEnrichment && isEnrichmentProjectModalOpen && (
            <NonViewableProjectModal
              isOpen={isEnrichmentProjectModalOpen}
              onClose={onEnrichmentProjectModalClose}
              tasktype="enrichment"
              skipSubscriptionCheck
            />
          )}
        </div>
      </div>
    </Suspense>
  )
}
export default ProjectsGrid

/**
 * Refreshes the grid every 10 seconds if any Projects on the current page are
 * not ready
 * @param gridApi
 * @param dataRendered
 * @param isFetchingRows
 * @param isTabVisible
 */
function usePollProjectReadiness(
  gridApi: GridApi<any> | null,
  dataRendered: boolean,
  isFetchingRows: boolean,
  isTabVisible: boolean
) {
  const toast = useToast()

  const checkIfProjectsReady = useCallback((): boolean => {
    if (gridApi && dataRendered && !isFetchingRows) {
      const curPage = gridApi.paginationGetCurrentPage()
      const pageSize = gridApi.paginationGetPageSize()
      const totalRows = gridApi.paginationGetRowCount()

      const curPageSize =
        (curPage + 1) * pageSize > totalRows ? totalRows - curPage * pageSize : pageSize

      const rows: { [index: number]: ProjectRowRes } = {}
      gridApi.forEachNode((node: IRowNode) => {
        if (node?.data && node?.rowIndex !== null) {
          rows[node.rowIndex] = node.data
        }
      })
      if (Object.keys(rows).length === 0) {
        return true
      }

      const rowData = [...Array(curPageSize).keys()].map(
        (i: number) => rows[i + curPage * pageSize]
      )
      return rowData.reduce((acc, row) => acc && (row?.is_ready || row?.has_error), true)
    }
    return false
  }, [dataRendered, gridApi, isFetchingRows])

  useInterval(
    () => {
      try {
        const projectsReady = checkIfProjectsReady()
        if (!projectsReady && isTabVisible) {
          gridApi?.refreshServerSide()
        }
      } catch (err) {
        logger.info(err)
        notifyAxiosError(toast, err as AxiosError)
      }
    },
    !checkIfProjectsReady() ? 10000 : null
  )
}
