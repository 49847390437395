import { StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

import { cleanlabColors } from './CleanlabColors'
import { typographyStyles } from './typography'

export const getGridStyles = (props: StyleFunctionProps) => {
  const focusColor = mode(cleanlabColors.focus, cleanlabColors.focusDarkMode)(props)
  return {
    // Balham theme
    '.ag-theme-balham, .ag-theme-balham-dark': {
      fontSize: '13px',
      borderRadius: '12px',
      '--ag-balham-active-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-foreground-color': mode(
        cleanlabColors.neutral[900],
        cleanlabColors.neutralDarkMode[900]
      )(props),
      '--ag-background-color': mode(
        cleanlabColors.neutral[0],
        cleanlabColors.neutralDarkMode[0]
      )(props),
      '--ag-header-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-subheader-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-control-panel-background-color': mode(
        cleanlabColors.neutral[0],
        cleanlabColors.neutralDarkMode[50]
      )(props),
      '--ag-border-color': mode(
        cleanlabColors.neutral[200],
        cleanlabColors.neutralDarkMode[200]
      )(props),
      '--ag-odd-row-background-color': mode(
        cleanlabColors.neutral[50],
        cleanlabColors.neutralDarkMode[50]
      )(props),
      '--ag-row-hover-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-column-hover-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-input-border-color': mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      '--ag-invalid-color': mode(cleanlabColors.red[600], cleanlabColors.redDarkMode[600])(props),
      '--ag-input-disabled-background-color': mode(
        cleanlabColors.neutral[200],
        cleanlabColors.neutralDarkMode[200]
      )(props),
      '--ag-checkbox-unchecked-color': mode(
        cleanlabColors.neutral[400],
        cleanlabColors.neutralDarkMode[400]
      )(props),
      '--ag-checkbox-checked-color': mode(
        cleanlabColors.blue[400],
        cleanlabColors.blueDarkMode[400]
      )(props),
      '--ag-input-focus-border-color': focusColor,
      '--ag-secondary-foreground-color': mode(
        cleanlabColors.neutral[700],
        cleanlabColors.neutralDarkMode[700]
      )(props),
      '--ag-disabled-foreground-color': mode(
        cleanlabColors.neutral[500],
        cleanlabColors.neutralDarkMode[500]
      )(props),
      '--ag-subheader-toolbar-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-row-border-color': mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      '--ag-chip-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-range-selection-background-color-2': mode(
        cleanlabColors.blue[200],
        cleanlabColors.blueDarkMode[200]
      )(props),
      '--ag-range-selection-background-color-3': mode(
        cleanlabColors.blue[300],
        cleanlabColors.blueDarkMode[300]
      )(props),
      '--ag-range-selection-background-color-4': mode(
        cleanlabColors.blue[400],
        cleanlabColors.blueDarkMode[400]
      )(props),
      '--ag-selected-row-background-color': mode(
        cleanlabColors.neutral[200],
        cleanlabColors.neutralDarkMode[200]
      )(props),
      '--ag-header-column-separator-color': mode(
        cleanlabColors.neutral[700],
        cleanlabColors.neutralDarkMode[700]
      )(props),
      '--ag-input-disabled-border-color': mode(
        cleanlabColors.neutral[400],
        cleanlabColors.neutralDarkMode[400]
      )(props),
      '--ag-header-cell-hover-background-color': mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      '--ag-range-selection-border-color': 'transparent',
      '--ag-range-selection-background-color': 'transparent',
      '--ag-range-selection-highlight-color': 'transparent',
    },
    // Alpine theme
    '.ag-theme-alpine, .ag-theme-alpine-dark': {
      fontSize: '13px',
      '--ag-alpine-active-color': focusColor,
      '--ag-foreground-color': mode(
        cleanlabColors.neutral[900],
        cleanlabColors.neutralDarkMode[900]
      )(props),
      '--ag-background-color': mode(
        cleanlabColors.neutral[0],
        cleanlabColors.neutralDarkMode[0]
      )(props),
      '--ag-header-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-subheader-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-control-panel-background-color': mode(
        cleanlabColors.neutral[0],
        cleanlabColors.neutralDarkMode[50]
      )(props),
      '--ag-border-color': mode(
        cleanlabColors.neutral[200],
        cleanlabColors.neutralDarkMode[200]
      )(props),
      '--ag-odd-row-background-color': mode(
        cleanlabColors.neutral[50],
        cleanlabColors.neutralDarkMode[50]
      )(props),
      '--ag-row-hover-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-secondary-border-color': mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      '--ag-header-bkg': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-tooltip-bkg': mode(
        cleanlabColors.neutral[800],
        cleanlabColors.neutralDarkMode[800]
      )(props),
      '--ag-odd-row-bkg': mode(
        cleanlabColors.neutral[50],
        cleanlabColors.neutralDarkMode[50]
      )(props),
      '--ag-column-control-panel-bkg': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-column-hover-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-input-border-color': mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      '--ag-invalid-color': mode(cleanlabColors.red[600], cleanlabColors.redDarkMode[600])(props),
      '--ag-input-disabled-background-color': mode(
        cleanlabColors.neutral[200],
        cleanlabColors.neutralDarkMode[200]
      )(props),
      '--ag-checkbox-unchecked-color': mode(
        cleanlabColors.neutral[400],
        cleanlabColors.neutralDarkMode[400]
      )(props),
      '--ag-checkbox-checked-color': mode(
        cleanlabColors.blue[400],
        cleanlabColors.blueDarkMode[400]
      )(props),
      '--ag-input-focus-border-color': focusColor,
      '--ag-secondary-foreground-color': mode(
        cleanlabColors.neutral[700],
        cleanlabColors.neutralDarkMode[700]
      )(props),
      '--ag-disabled-foreground-color': mode(
        cleanlabColors.neutral[500],
        cleanlabColors.neutralDarkMode[500]
      )(props),
      '--ag-subheader-toolbar-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-row-border-color': mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      '--ag-chip-background-color': mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      '--ag-range-selection-background-color-2': mode(
        cleanlabColors.blue[200],
        cleanlabColors.blueDarkMode[200]
      )(props),
      '--ag-range-selection-background-color-3': mode(
        cleanlabColors.blue[300],
        cleanlabColors.blueDarkMode[300]
      )(props),
      '--ag-range-selection-background-color-4': mode(
        cleanlabColors.blue[400],
        cleanlabColors.blueDarkMode[400]
      )(props),
      '--ag-selected-row-background-color': mode(
        cleanlabColors.neutral[200],
        cleanlabColors.neutralDarkMode[200]
      )(props),
      '--ag-header-column-separator-color': mode(
        cleanlabColors.neutral[700],
        cleanlabColors.neutralDarkMode[700]
      )(props),
      '--ag-input-disabled-border-color': mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      '--ag-range-selection-border-color': 'transparent',
      '--ag-range-selection-background-color': 'transparent',
      '--ag-range-selection-highlight-color': 'transparent',
    },
    // Dashboard tables theme
    '[data-grid-theme=dashboard]': {
      '--ag-foreground-color': mode(
        cleanlabColors.text.strong,
        cleanlabColors.textDarkMode.strong
      )(props),
      '--ag-header-background-color': mode(
        cleanlabColors.surface['1'],
        cleanlabColors.surfaceDarkMode['1']
      )(props),
      '--ag-background-color': mode(
        cleanlabColors.surface['0'],
        cleanlabColors.surfaceDarkMode['0']
      )(props),
      '--ag-odd-row-background-color': mode(
        cleanlabColors.surface[1],
        cleanlabColors.surfaceDarkMode[1]
      )(props),
      '--ag-row-hover-color': mode(
        cleanlabColors.surface['1-hover'],
        cleanlabColors.surfaceDarkMode['1-hover']
      )(props),
      '--ag-border-color': mode(
        cleanlabColors.border['0'],
        cleanlabColors.borderDarkMode['0']
      )(props),
      '--ag-row-border-color': 'transparent',
      '--ag-row-border-width': '2',
      '--ag-range-selection-border-color': mode(
        cleanlabColors.border['1'],
        cleanlabColors.borderDarkMode['1']
      )(props),
      '--ag-header-height': '48px',
      '.ag-root-wrapper': {
        borderColor: mode(cleanlabColors.border['1'], cleanlabColors.borderDarkMode['1'])(props),
      },
      '.ag-pinned-right-header': {
        borderLeftColor: 'transparent',
      },
      '.ag-paging-panel': {
        ...typographyStyles.utilities.caption,
        color: mode(cleanlabColors.text.primary, cleanlabColors.textDarkMode.primary)(props),
      },
      '.ag-paging-row-summary-panel-number': {
        ...typographyStyles.utilities['caption-medium'],
      },
    },
    '.added-column': {
      backgroundColor: mode(
        cleanlabColors.neutral[300],
        cleanlabColors.neutralDarkMode[300]
      )(props),
      color: mode(cleanlabColors.neutral[800], cleanlabColors.neutralDarkMode[800])(props),
    },
    '.added-column:hover': {
      backgroundColor: mode(
        `${cleanlabColors.neutral[400]} !important`,
        `${cleanlabColors.neutralDarkMode[400]} !important`
      )(props),
    },
    '.dataset-column': {
      backgroundColor: mode(
        cleanlabColors.neutral[100],
        cleanlabColors.neutralDarkMode[100]
      )(props),
      padding: '8px 12px !important',
      gap: '2px !important',
      '--ag-header-column-separator-height': '70%',
    },
    '.dataset-column:hover': {
      backgroundColor: mode(
        `${cleanlabColors.neutral[200]} !important`,
        `${cleanlabColors.neutralDarkMode[200]} !important`
      )(props),
    },
    '.ag-root-wrapper': {
      borderRadius: '12px',
    },
    '.ag-header-cell-resize::after': {
      backgroundColor: mode(
        cleanlabColors.neutral[700],
        cleanlabColors.neutralDarkMode[700]
      )(props),
    },
  }
}
