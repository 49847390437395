import { Box, Button, Flex, HStack, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import useGridClassname from '@hooks/useGridClassname'
import { UserQuota } from '@services/userQuota/constants'
import { useDeleteUserQuotasMutation } from '@services/userQuota/mutations'
import { useUserQuotas } from '@services/userQuota/queries'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import React, { useCallback, useRef, useState } from 'react'

import ConfirmDeleteDialog from '../confirmDeleteDialog/ConfirmDeleteDialog'
import UpdateQuotaModal from '../updateQuotaModal/UpdateQuotaModal'
import {
  columnDefs,
  dataStorageFormatter,
  timeFormatter,
  tlmTokenLimitFormatter,
} from './UserQuotasGrid.helpers'

const UserQuotaGrid = () => {
  const numRowsPerPage = 20
  const toast = useToast()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const cancelDeleteRef = useRef(null)
  const userQuotas = useUserQuotas()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const { mutate: deleteUserQuotas } = useDeleteUserQuotasMutation()

  const updateSelectedIds = useCallback(() => {
    const ids = (gridApi?.getSelectedRows() ?? []).map((row: UserQuota) => row.userId)
    if (ids.length === 0) {
      toast({
        ...defaultToastProps,
        description: 'Please select at least one user',
        status: 'warning',
      })
    } else {
      setSelectedIds(ids)
    }
    return ids
  }, [gridApi, toast])

  const handleClickUpdate = useCallback(() => {
    const ids = updateSelectedIds()
    if (ids.length) {
      onUpdateOpen()
    }
  }, [onUpdateOpen, updateSelectedIds])

  const handleClickDelete = useCallback(() => {
    const ids = updateSelectedIds()
    if (ids.length) {
      onDeleteOpen()
    }
  }, [onDeleteOpen, updateSelectedIds])

  const handleConfirmDelete = useCallback(() => {
    deleteUserQuotas(selectedIds)
  }, [deleteUserQuotas, selectedIds])

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
  }

  const gridOptions: GridOptions = {
    columnDefs,
    rowSelection: 'multiple',
    components: {
      dataStorageFormatter,
      timeFormatter,
      tlmTokenLimitFormatter,
    },
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    },
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  return (
    <Stack>
      <Flex justify="flex-end">
        <HStack>
          <Button colorScheme="whatsapp" size="sm" onClick={handleClickUpdate}>
            Update user quotas
          </Button>
          <Button colorScheme="red" size="sm" onClick={handleClickDelete}>
            Delete user quota
          </Button>
        </HStack>
        <ConfirmDeleteDialog
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          cancelRef={cancelDeleteRef}
          onConfirm={handleConfirmDelete}
          item="user quota"
        />
      </Flex>
      <Box className={useGridClassname()} h="70vh" w="100%">
        <CleanlabGrid
          gridOptions={gridOptions}
          onGridReady={handleGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={userQuotas}
        />
      </Box>
      <UpdateQuotaModal isOpen={isUpdateOpen} onClose={onUpdateClose} selectedIds={selectedIds} />
    </Stack>
  )
}

export default UserQuotaGrid
