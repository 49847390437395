import { Box, Flex, HStack, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import DeleteRowModal from '@common/modals/deleteRowModal/DeleteRowModal'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButtonWithIcon from '@components/buttons/secondaryButtonWithIcon/SecondaryButtonWithIcon'
import { useEventTracking } from '@hooks/useEventTracking'
import useGridClassname from '@hooks/useGridClassname'
import useIsTabActive from '@hooks/useIsTabActive'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import deploymentApiService from '@services/deploymentApi'
import { downloadFileFromUrl } from '@utils/functions/downloadFile'
import { timestampToDate } from '@utils/functions/timestampToDate'
import {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { FiTrash } from 'react-icons/fi'

import ExportInferenceModal from './exportInferenceModal/ExportInferenceModal'
import statusEnumToDesc from './ExportModelGrid.helpers'
import { ExportModelGridProps, PREDICTION_STATUS, QueryRowProps } from './ExportModelGrid.types'
import ExportModelStatusCellComponent from './exportModelStatusCellComponent/exportModelStatusCellComponent'

const ExportModelGrid = (props: ExportModelGridProps) => {
  const { data, deploymentId, refreshData, cleansetDetails } = props

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const { isOpen: isExportOpen, onOpen: onExportOpen, onClose: onExportClose } = useDisclosure()

  const { trackEvent } = useEventTracking()
  const isTabActive = useIsTabActive()
  const { isIndividualPayingCustomer } = useSubscription()

  const numRowsPerPage = 10
  const toast = useToast()
  const [exportButtonLoading, setExportButtonLoading] = useState(false)
  const [targetRowData, setTargetRowData] = useState<QueryRowProps | null>(null)
  const [queryId, setQueryId] = useState<string>('')
  const [exportSuccess, setExportSuccess] = useState<boolean>(false)
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
  }

  useEffect(() => {
    if (data) {
      if (
        !data.every(
          (row) =>
            row.status === PREDICTION_STATUS.COMPLETE ||
            row.status === PREDICTION_STATUS.FAILED ||
            row.status === PREDICTION_STATUS.QUOTA_FAILURE
        ) &&
        isTabActive
      ) {
        setTimeout(() => {
          refreshData()
        }, 10000)
      }
    }
  }, [data, isTabActive, refreshData])

  const ExportCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Flex align="center" h="100%">
        <HStack>
          <PrimaryButton
            height="32px"
            fontSize="sm"
            isLoading={exportButtonLoading}
            isDisabled={rowData.status !== 'COMPLETE'}
            onClick={() => {
              trackEvent(MixpanelEvents.clickExportInferenceButton, {
                ...cleansetDetails,
                deploymentId: deploymentId,
                queryId: rowData.id,
                testDatasetName: rowData.dataset,
              })
              if (isIndividualPayingCustomer && !rowData.is_exported) {
                setQueryId(rowData.id)
                onExportOpen()
              } else {
                handleExport(rowData.id, rowData.is_exported)
              }
            }}
          >
            Export
          </PrimaryButton>
          <SecondaryButtonWithIcon
            height="32px"
            leftIcon={<Icon as={FiTrash} />}
            onClick={() => {
              trackEvent(MixpanelEvents.clickDeleteInferenceButton, {
                ...cleansetDetails,
                deploymentId: deploymentId,
                queryId: rowData.id,
                testDatasetName: rowData.dataset,
              })
              setTargetRowData(rowData)
              onDeleteOpen()
            }}
          >
            Delete
          </SecondaryButtonWithIcon>
        </HStack>
      </Flex>
    )
  }

  const DatasetCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Flex align="center" height="100%">
        <Text fontSize="sm">{rowData.dataset}</Text>
      </Flex>
    )
  }

  const DateCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Flex align="center" height="100%">
        <Text fontSize="sm">{timestampToDate(rowData.date)}</Text>
      </Flex>
    )
  }

  const ExportModelStatusRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <ExportModelStatusCellComponent
        status={params.data.status ?? PREDICTION_STATUS.FAILED}
        statusDescription={statusEnumToDesc(params.data.status ?? PREDICTION_STATUS.FAILED)}
        datasetName={rowData.dataset}
        error={rowData.error_msg}
      />
    )
  }

  const columnDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      cellRenderer: 'dateCellRenderer',
    },
    { field: 'dataset', headerName: 'Dataset', cellRenderer: 'datasetCellRenderer' },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: 'statusCellRenderer',
    },
    {
      field: 'exportResults',
      headerName: 'Export Results',
      cellRenderer: 'exportCellRenderer',
      minWidth: 150,
    },
    {
      field: 'error_msg',
      headerName: 'Error Message',
      hide: true,
    },
  ]

  const handleExport = async (queryId: string, previouslyExported?: boolean) => {
    setExportButtonLoading(true)
    try {
      const pollQueryResponse = await deploymentApiService.pollQuery(deploymentId, queryId)
      if (
        pollQueryResponse &&
        pollQueryResponse.results &&
        pollQueryResponse.status === 'COMPLETE'
      ) {
        downloadFileFromUrl(pollQueryResponse.results)
        setExportButtonLoading(false)
        if (!previouslyExported) {
          setExportSuccess(true)
        }
        refreshData()
        gridApi?.refreshCells()
        toast({
          ...defaultToastProps,
          status: 'success',
          description: 'Export success! Your download will begin shortly.',
        })
      }
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError, { title: 'Export failed.' })
    }
    setExportButtonLoading(false)
  }

  const handleDeleteQuery = async () => {
    setExportButtonLoading(true)
    const queryId = targetRowData ? targetRowData.id : ''
    trackEvent(MixpanelEvents.clickModalDeleteInferenceButton, {
      ...cleansetDetails,
      deploymentId: deploymentId,
      queryId: queryId,
      testDatasetName: targetRowData ? targetRowData.dataset : '',
    })
    try {
      await deploymentApiService.deleteQuery(queryId)
      refreshData()
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to delete query.' })
    }
    setExportButtonLoading(false)
  }

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    rowHeight: 60,
    columnDefs,
    rowSelection: 'multiple',
    onGridReady: (params) => {
      params.api.sizeColumnsToFit()
    },
    components: {
      statusCellRenderer: ExportModelStatusRenderer,
      exportCellRenderer: ExportCellRenderer,
      datasetCellRenderer: DatasetCellRenderer,
      dateCellRenderer: DateCellRenderer,
    },
    defaultColDef: {
      filter: false,
      sortable: false,
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      cellStyle: {
        borderRight: '1px solid',
        borderRightColor: 'var(--ag-row-border-color)',
      },
    },
    suppressScrollOnNewData: true,
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  return (
    <Box className={useGridClassname()} w="100%" mt="2rem">
      {queryId && (
        <ExportInferenceModal
          isOpen={isExportOpen}
          onClose={() => {
            setExportSuccess(false)
            onExportClose()
          }}
          queryId={queryId}
          handleExport={() => handleExport(queryId)}
          exportButtonLoading={exportButtonLoading}
          exportSuccess={exportSuccess}
        />
      )}
      <DeleteRowModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        rowData={{
          name: `the ${targetRowData ? targetRowData.dataset : ''} inference`,
        }}
        handleDeleteButtonClicked={handleDeleteQuery}
        deletionType="inference"
        size="large"
      />
      <CleanlabGrid
        gridOptions={gridOptions}
        pagination
        paginationPageSize={numRowsPerPage}
        rowData={data}
        onGridReady={handleGridReady}
      />
    </Box>
  )
}

export default ExportModelGrid
