import { CreateToastFnReturn } from '@chakra-ui/react'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import logger from '@utils/logger'
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { AxiosError } from 'axios'

export interface ServerType {
  getData: (
    cleansetId: string,
    request: IServerSideGetRowsRequest
  ) => Promise<Record<string, unknown>>
}

// create the server side datasource for the table
export const createServerSideDatasource = (
  server: ServerType,
  cleansetId: string,
  toast: CreateToastFnReturn,
  setInitialized: VoidFunction,
  setIsFetchingRows?: (isFetchingRows: boolean) => void
): IServerSideDatasource => {
  logger.info('creating server side datasource from', server)

  return {
    getRows: function (params: IServerSideGetRowsParams) {
      logger.info(
        '[Datasource] - rows requested by grid: startRow = ' +
          params.request.startRow +
          ', endRow = ' +
          params.request.endRow
      )
      const fetchData = async () => {
        setIsFetchingRows && setIsFetchingRows(true)
        try {
          const data = await server.getData(cleansetId, params.request)
          params.success({
            rowData: data.rows as any[],
            rowCount: data.row_count as number,
          })

          setInitialized()
        } catch (err) {
          console.error('createServerSideDataSource getData() failed', err)
          notifyAxiosError(toast, err as AxiosError)
          params.fail()
        }
        setIsFetchingRows && setIsFetchingRows(false)
      }
      fetchData()
    },
  }
}
