import { CLEANLAB_FRONTEND_COLUMN } from '../datasheet/Datasheet.types'
import { FilterData, FilterOperator, FilterState } from './FilterState.types'
import { getPresetFilters } from './InitialFilterState'

const closeAllFilters = (filterState: FilterState): FilterState => ({
  ...filterState,
  addFilterMenuOpen: false,
  filterData: filterState.filterData.map((filter) => ({ ...filter, isOpen: false })),
})

const applySingleFilter = (currentFilters: FilterData[], filterData: FilterData): FilterData[] => {
  const newFilters = currentFilters.slice()
  const maxApplicationOrder = Math.max(...currentFilters.map((e) => e.applicationOrder || 0))
  const existingFilterIdx = currentFilters.findIndex(
    (filter) => filter.property === filterData.property
  )
  if (existingFilterIdx > -1) {
    newFilters[existingFilterIdx] = { ...filterData, applicationOrder: maxApplicationOrder + 1 }
  } else {
    newFilters.push(filterData)
  }
  return newFilters
}

const removeSingleFilter = (
  currentFilters: FilterData[],
  filterProperty: string,
  isMultilabel: boolean
): FilterData[] => {
  const newFilters = currentFilters.slice()
  const existingFilterIdx = currentFilters.findIndex((filter) => filter.property === filterProperty)
  const currentFilterData = currentFilters[existingFilterIdx]
  // currently, we have array filter inputs for the given and suggested filters for multilabel projects
  const inputIsArray =
    isMultilabel &&
    (filterProperty === CLEANLAB_FRONTEND_COLUMN.SUGGESTED || CLEANLAB_FRONTEND_COLUMN.GIVEN)
  if (existingFilterIdx > -1) {
    if (
      getPresetFilters(isMultilabel).find(
        (filter) => filter.property === currentFilterData.property
      )
    ) {
      // is a preset filter
      newFilters[existingFilterIdx] = {
        ...currentFilterData,
        filterType: FilterOperator.All,
        filterInput: inputIsArray ? [] : '',
        isChecked: false,
      }
      return newFilters
    } else {
      //is a custom filter
      return newFilters.filter((filter) => filter.property !== currentFilterData.property)
    }
  }
  // filter does not exist
  return newFilters
}

const addTagFilter = (currentFilters: FilterData[], filterData: FilterData): FilterData[] => {
  const newFilters = currentFilters.slice()
  const existingFilterIdx = currentFilters.findIndex(
    (filter) => filter.property === filterData.property
  )
  if (existingFilterIdx > -1) {
    const currentFilterInput = currentFilters[existingFilterIdx].filterInput
    if (Array.isArray(currentFilterInput)) {
      const tagFilterValue = filterData.filterInput.toString()
      const updatedFilterInput =
        tagFilterValue === '_empty' ? [] : currentFilterInput.filter((tag) => tag !== '_empty')
      newFilters[existingFilterIdx] = {
        ...filterData,
        filterInput: [...updatedFilterInput, tagFilterValue],
      }
    }
  }
  return newFilters
}

const removeTagFilter = (currentFilters: FilterData[], filterData: FilterData): FilterData[] => {
  const newFilters = currentFilters.slice()
  const existingFilterIdx = currentFilters.findIndex(
    (filter) => filter.property === filterData.property
  )
  if (existingFilterIdx > -1) {
    const currentFilterInput = currentFilters[existingFilterIdx].filterInput
    if (Array.isArray(currentFilterInput)) {
      const tagFilterValue = filterData.filterInput.toString()
      newFilters[existingFilterIdx] = {
        ...filterData,
        filterInput: currentFilterInput.filter((tag) => tag !== tagFilterValue),
      }
    }
  }
  return newFilters
}

const filterDataEqual = (filter1: FilterData, filter2: FilterData): boolean => {
  return (
    filter1.property === filter2.property &&
    filter1.filterInput === filter2.filterInput &&
    filter1.filterType === filter2.filterType
  )
}

const deepCompareFilterData = (prevState: FilterData[], currState: FilterData[]): boolean => {
  if (prevState.length !== currState.length) {
    return false
  } else {
    return prevState.every((filter, index) => filterDataEqual(filter, currState[index]))
  }
}

export {
  addTagFilter,
  applySingleFilter,
  closeAllFilters,
  deepCompareFilterData,
  removeSingleFilter,
  removeTagFilter,
}
