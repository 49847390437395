import { UseToastOptions } from '@chakra-ui/react'

export const defaultToastProps: UseToastOptions = {
  id: 'toast-id',
  status: 'warning',
  variant: 'left-accent',
  position: 'bottom-right',
  duration: 10000,
  isClosable: true,
}

export const defaultToastAlertProps: UseToastOptions = {
  duration: 5000,
  position: 'top-right',
  isClosable: true,
}
