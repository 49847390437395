import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useRegisterForNotification } from '@services/user/mutations'
import React, { useCallback, useState } from 'react'

import { RegisterNotificationProps } from './RegisterNotification.types'

const RegisterNotification = ({
  uploadId,
  eventId,
  cleansetId,
  children,
  onClick,
  onClose,
}: RegisterNotificationProps) => {
  const { mutate: registerForNotification, isLoading } = useRegisterForNotification()
  const [showModal, setShowModal] = useState(false)

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
    setShowModal(true)
    registerForNotification({ uploadId, eventId, cleansetId })
  }, [cleansetId, eventId, onClick, registerForNotification, uploadId])

  return (
    <>
      <Link onClick={handleClick}>{children}</Link>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
          onClose && onClose()
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="md" pb={8}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Text>
                A member of our team will be in contact with you once this issue is resolved!
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RegisterNotification
