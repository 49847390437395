import { useToast } from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys as enrichmentProjectQueryKeys } from '@services/enrichment/constants'
import { enrichmentProjectApi } from '@services/enrichmentApi'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useDeleteEnrichmentProjectMutation = (refreshData: () => void) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (projectId: string) => {
      const accessToken = await auth.getTokenSilently()
      return await enrichmentProjectApi.delete(
        `projects/${projectId}`,
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(enrichmentProjectQueryKeys.enrichmentProjects.all())
      refreshData()
      toast({
        ...defaultToastProps,
        status: 'success',
        description: 'Deleted enrichment Project',
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to delete enrichment Project' })
    },
  })
}
