import Loading from '@common/layout/loading/Loading'
import { Button } from '@components/button/Button'
import { FormField } from '@components/formField/FormField'
import { DatasetRowProps } from '@services/datasetApi'
import { useDatasets } from '@services/datasets/queries'
import { useForm } from '@tanstack/react-form'
import { timestampToDate, timeStampToDateOnly } from '@utils/functions/timestampToDate'
import { FormEventHandler, memo, Suspense, useCallback, useEffect, useId, useMemo } from 'react'
import { Link, LoaderFunction, redirect, useNavigate } from 'react-router-dom'
import testIds from 'src/playwright/testIds'

import { CleanlabSelect } from './CleanlabSelect'
import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  getProjectNameFromDatasetId,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { ProjectNameField } from './ProjectNameField'
import {
  ProjectWizardStep,
  ProjectWizardStepper,
  useProjectWizardStore,
} from './projectWizard/ProjectWizard.store'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcome,
} from './Sidebar'

const THIS_STEP = 'selectDataset' as const satisfies ProjectWizardStep

export const DatasetDetailsFields = ({
  datasetDetails,
}: {
  datasetDetails?: Partial<Pick<DatasetRowProps, 'num_rows' | 'upload_date'>>
}) => {
  const { num_rows, upload_date } = datasetDetails ?? {}
  return (
    <>
      {typeof num_rows === 'number' && (
        <FormField
          label="No. of rows"
          render={(formFieldProps) => {
            return (
              <p id={formFieldProps?.htmlFor} className="type-body-100 text-text-faint">
                {num_rows}
              </p>
            )
          }}
        />
      )}
      {!!upload_date && (
        <FormField
          label="Uploaded on"
          render={(formFieldProps) => {
            return (
              <p id={formFieldProps?.htmlFor} className="type-body-100 text-text-faint">
                {timestampToDate(upload_date)}
              </p>
            )
          }}
        />
      )}
    </>
  )
}

const DatasetSelectOption = memo(
  ({ dataset: { id, name, num_rows, upload_date } }: { dataset: DatasetRowProps }) => {
    return (
      <option key={id} value={id}>
        {name}
        {typeof num_rows === 'number' && <>&nbsp;&nbsp;–&nbsp;&nbsp;Rows: {num_rows}</>}
        {!!upload_date && (
          <>&nbsp;&nbsp;–&nbsp;&nbsp;Uploaded: {timeStampToDateOnly(upload_date)}</>
        )}
      </option>
    )
  }
)

const Form = () => {
  const navigate = useNavigate()
  const datasetSelectId = useId()
  const setValuesForStep = useProjectWizardStore((state) => state.setValuesForStep)
  const intialProjectName = useProjectWizardStore((s) => s.projectName) ?? ''
  const initialDatasetId = useProjectWizardStore((s) => s.datasetId) ?? ''

  const form = useForm({
    defaultValues: {
      projectName: intialProjectName,
      datasetId: initialDatasetId,
    },
    onSubmit: async ({ value }) => {
      setValuesForStep(THIS_STEP, {
        datasetId: value.datasetId,
        projectName: value.projectName,
        startPoint: 'empty',
      })
      const url = `/clean/${value.datasetId}/type`
      navigate(url)
    },
  })
  const formState = form.useStore((s) => ({
    canSubmit: s.canSubmit,
  }))
  const datasetId = form.useStore((s) => s.values.datasetId)

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      form.handleSubmit()
    },
    [form]
  )

  const { data: datasets } = useDatasets({ useQueryOptions: { suspense: true } })
  const filteredSortedDatasets = useMemo(
    () =>
      datasets
        .filter((dataset) => {
          return dataset?.complete
        })
        .sort((a, b) => b.upload_date - a.upload_date),
    [datasets]
  )
  const selectedDataset = datasets.find((ds) => ds?.id === datasetId)

  useEffect(() => {
    if (datasets?.length === 0) {
      navigate('/clean')
    }
  }, [datasets, navigate])
  if (datasets?.length === 0) {
    return null
  }

  return (
    <form className="flex flex-col gap-8" onSubmit={onSubmit} noValidate>
      <div className="flex flex-col gap-6">
        <form.Field
          name="datasetId"
          validators={{
            onChange: ({
              value,
              fieldApi: {
                form: { getFieldMeta, getFieldValue, setFieldMeta, setFieldValue, validateField },
              },
            }) => {
              const projectName = getFieldValue('projectName')
              const projectNameMeta = getFieldMeta('projectName')
              if (projectNameMeta?.isPristine || !projectName) {
                const datasetId = getFieldValue('datasetId')
                const currentProjectName = getFieldValue('projectName')
                const autoProjectName = getProjectNameFromDatasetId(datasetId, datasets)
                if (autoProjectName && currentProjectName !== autoProjectName) {
                  setFieldValue('projectName', autoProjectName, { touch: true })
                  setFieldMeta('projectName', (meta) => ({
                    ...meta,
                    isDirty: false,
                  }))
                  validateField('projectName', 'change')
                }
              }
              if (!value) {
                return 'Please select a Dataset'
              }
              return undefined
            },
          }}
        >
          {({ state, handleBlur, handleChange }) => {
            const meta = state.meta
            return (
              <FormField
                htmlFor={datasetSelectId}
                required={true}
                label="Select a Dataset"
                description={
                  <>
                    The dropdown menu below reflects which Datasets are currently available in your
                    account.
                  </>
                }
                error={meta?.errors?.[0]}
              >
                <CleanlabSelect
                  data-testid={testIds.createProjectPageDatasetSelect}
                  error={meta?.errors?.[0] || undefined}
                  id={datasetSelectId}
                  placeholder="Choose from your existing Datasets"
                  value={state.value}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e.target.value)
                  }}
                >
                  {filteredSortedDatasets?.map((dataset) => (
                    <DatasetSelectOption key={dataset.id} dataset={dataset} />
                  ))}
                </CleanlabSelect>
              </FormField>
            )
          }}
        </form.Field>
        <ProjectNameField form={form} />
      </div>
      <DatasetDetailsFields datasetDetails={selectedDataset} />
      <ProjectWizardActions step={THIS_STEP}>
        <Button variant="highContrast" size="medium" type="submit" disabled={!formState.canSubmit}>
          Next
        </Button>
      </ProjectWizardActions>
    </form>
  )
}

export const createProjectFromDatasetLoader: LoaderFunction = ({ request }) => {
  const { resetState } = useProjectWizardStore.getState()
  const url = new URL(request.url)
  const resetParam = url.searchParams.get('reset')
  if (typeof resetParam === 'string') {
    url.searchParams.delete('reset')
    resetState()
    return redirect(url.toString())
  }
  return true
}

const CreateProjectFromDataset = () => {
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          <Link to="/clean">Create a new Project</Link>
        </PreviousLink>
        <ProjectWizardStepper step={THIS_STEP} />
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Select an existing Dataset</PageHeading>
            <PageDescription>
              Each Dataset in Cleanlab can be associated to multiple Projects - select an existing
              Dataset below to get started.
            </PageDescription>
          </HeadingWrapper>
          <Suspense fallback={<Loading />}>
            <Form />
          </Suspense>
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/">
              Cleanlab Studio Documentation
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/FAQ/">
              Cleanlab Studio FAQ
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/projects/">
              Project guidebook
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}

export default CreateProjectFromDataset
