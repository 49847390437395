import { ITooltipParams } from 'ag-grid-community'

import { ColumnStatus } from '../../Cleanset.types'
import { getBackendColName, getColumnStatus } from '../Datasheet.helpers'
import { CLEANLAB_FRONTEND_COLUMN } from '../Datasheet.types'
import { ColumnPin } from './ColumnDefs'

export const getTooltipByColumnStatus = (
  columnName: string,
  columnDisplayName: string,
  params: ITooltipParams
) => {
  const backendColName = getBackendColName(columnName)
  const columnStatus = getColumnStatus(backendColName, params.context.datasheetColumnStatuses)
  switch (columnStatus) {
    case ColumnStatus.ERROR:
      return `Error generating column ${columnDisplayName}`
    case ColumnStatus.PENDING:
      return `Column ${columnDisplayName} not yet ready`
  }
}

export const getColumnPin = (
  modality: string,
  column: string,
  isDatasetColumn = false
): ColumnPin => {
  if (modality === 'tabular' && column !== CLEANLAB_FRONTEND_COLUMN.ACTION && !isDatasetColumn) {
    return 'right'
  } else if (modality === 'tabular') {
    return 'left'
  } else {
    return null
  }
}

export const getDatasetColumnWidth = (
  isPredictiveColumn: boolean,
  isTextProject: boolean
): number => {
  if (isPredictiveColumn && isTextProject) {
    return window.innerWidth * 0.4
  } else {
    return 110
  }
}
