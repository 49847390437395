import { useToast } from '@chakra-ui/react'
import { defaultToastProps } from '@common/alerts/defaultToastProps'
import Layout from '@common/layout/Layout'
import Loading from '@common/layout/loading/Loading'
import { useAuth } from '@hooks/useAuth'
import { AuthenticationGuard } from '@providers/authentication/AuthenticationGuard'
import { useConfirmEmail } from '@services/user/mutations'
import { useMyself } from '@services/user/queries'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { usePostHog } from 'posthog-js/react'
import { Suspense, useEffect } from 'react'
import { Outlet, RouteObject, useSearchParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { projectWizardRoutes } from './createProjectRoutes'
import NotFound from './pages/404/NotFound'
import Account from './pages/account/Account'
import Activate from './pages/activate/Activate'
import AdminPanel from './pages/adminPanel/AdminPanel'
import Cleanset from './pages/cleanset/Cleanset'
import CleansetChartsDisplay from './pages/cleansetCharts/CleansetChartsDisplay'
import CleansetVersions from './pages/cleansetVersions/CleansetVersions'
import DatasetDetails from './pages/datasetDetails/DatasetDetails'
import Deployment from './pages/deployment/Deployment'
import Home, { HomeLayout } from './pages/home/Home'
import InvalidEmailPage from './pages/invalidEmailPage/InvalidEmailPage'
import RedirectAuth from './pages/redirectAuth/RedirectAuth'
import Tlm from './pages/tlmPage/TlmPage'
import VpcAdminPanel from './pages/vpcAdminPanel/VpcAdminPanel'
import VpcUsage from './pages/vpcUsage/VpcUsage'
import { uploadDatasetRoutes } from './uploadDatasetRoutes'

// Should use higher order components outside of renders, otherwise
// things like the Guide will trigger a rerender
const ProtectedCleanset = <AuthenticationGuard component={Cleanset} />
const ProtectedAccount = <AuthenticationGuard component={Account} />
const ProtectedCleansetVersions = <AuthenticationGuard component={CleansetVersions} />
const ProtectedDatasetDetails = <AuthenticationGuard component={DatasetDetails} />
const ProtectedDeployment = <AuthenticationGuard component={Deployment} />
const ProtectedNotFound = <AuthenticationGuard component={NotFound} />
const ProtectedAdminPanel = <AuthenticationGuard component={AdminPanel} />
const ProtectedVpcAdminPanel = <AuthenticationGuard component={VpcAdminPanel} />
const ProtectedVpcUsage = <AuthenticationGuard component={VpcUsage} />
const ProtectedCleansetChartsDisplay = <AuthenticationGuard component={CleansetChartsDisplay} />

const AppRoutesLayout = () => {
  const posthog = usePostHog()

  const toast = useToast()

  const {
    activated: isActivated,
    id: userId,
    email: userEmail,
    name: userName,
    apiKey,
  } = useMyself()

  const [searchParams, setSearchParams] = useSearchParams()

  const signupToken = searchParams.get('token')

  const { mutate: confirmEmailMutation } = useConfirmEmail({
    onError: () => {
      toast({
        ...defaultToastProps,
        variant: 'solid',
        description:
          'Failed to confirm email. Please try to click the activation link again, or contact support via the chat at the bottom left of your window.',
        status: 'error',
      })
    },
  })

  const { boot, isOpen } = useIntercom()

  const { isAuthenticated, user } = useAuth()

  useEffect(() => {
    if (isAuthenticated && !isOpen && !IS_VPC_ENVIRONMENT) {
      boot({ name: user?.name, email: user?.email })
    }
  }, [boot, isOpen, isAuthenticated, user?.name, user?.email])

  useEffect(() => {
    if (isActivated && signupToken) {
      confirmEmailMutation({ token: signupToken })
      searchParams.delete('token')
      setSearchParams(searchParams)
    }
  }, [confirmEmailMutation, isActivated, searchParams, setSearchParams, signupToken])

  useEffect(() => {
    if (!IS_VPC_ENVIRONMENT && isAuthenticated) {
      posthog.identify(userId, { email: userEmail, name: userName, apiKey })
    }
  }, [isAuthenticated, isActivated, userId, userEmail, userName, apiKey, posthog])

  return (
    <Suspense
      fallback={
        <Layout>
          <Loading />
        </Layout>
      }
    >
      <Outlet />
    </Suspense>
  )
}

const ProtectedHomeLayout = (
  <AuthenticationGuard
    component={() => {
      return (
        <HomeLayout>
          <Outlet />
        </HomeLayout>
      )
    }}
  />
)

// Namespace is shared with backend, which means that certain prefixes CANNOT be used
// /api, /private_api, and /headless_browser_api are all reserved
const appRoutes: RouteObject[] = [
  {
    element: <AppRoutesLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        element: ProtectedHomeLayout,
        children: [
          ...(IS_VPC_ENVIRONMENT ? [] : [{ path: '/tlm', element: <Tlm /> }]),
          projectWizardRoutes,
          ...uploadDatasetRoutes,
        ],
      },
      { path: '/activate', element: <Activate /> },
      { path: '/login', element: <RedirectAuth signup /> },
      { path: '/signup', element: <RedirectAuth signup /> },
      { path: '/invalidEmail', element: <InvalidEmailPage /> },
      { path: '/account', element: ProtectedAccount },
      { path: '/admin', element: ProtectedAdminPanel },
      { path: '/vpc-admin', element: ProtectedVpcAdminPanel },
      { path: '/vpc-usage', element: ProtectedVpcUsage },
      { path: '/datasets/:datasetId', element: ProtectedDatasetDetails },
      { path: '/projects/:projectId', element: ProtectedCleansetVersions },
      { path: '/cleansets/:cleansetId', element: ProtectedCleanset },
      { path: '/cleansets/:cleansetId/labeling', element: ProtectedCleanset },
      { path: '/deployments/:deploymentId', element: ProtectedDeployment },
      { path: '/charts/:cleansetId', element: ProtectedCleansetChartsDisplay },
      { path: '*', element: ProtectedNotFound },
    ],
  },
]

export default appRoutes
