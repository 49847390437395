export enum DatasheetColumnType {
  CUSTOM = 'custom',
  ISSUE = 'issue',
  SCORE = 'score',
}

export interface DatasheetColumnConfig {
  featureFlag?: string
  isInitiallyHidden: boolean
  headerName: string
  tooltip?: string
  columnType: DatasheetColumnType
  backendColumnName?: string
  isNumberColumn?: boolean
  tooltipLabel?: string
}
