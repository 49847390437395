import { FormControl, Switch, Tooltip, useDisclosure } from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconArrowRight } from '@components/icons'
import { Kbd } from '@components/kbd/Kbd'
import { useEventTracking } from '@hooks/useEventTracking'
import { useKeyPress } from '@hooks/useKeyPress'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useCallback, useContext } from 'react'

import { CleansetContext } from '../../CleansetContext'
import HelpModal from '../../helpModal/HelpModal'

interface ResolverHeadingProps {
  isMultiSelect: boolean
  setEditMode: (editMode: boolean) => void
  autoAdvance: boolean
  setAutoAdvance: (autoAdvance: boolean) => void
  isTemplate: boolean
}

const ResolverHeading = (props: ResolverHeadingProps) => {
  const { isMultiSelect, setAutoAdvance, setEditMode, autoAdvance, isTemplate } = props

  const { isOpen: isHelpOpen, onOpen: onHelpOpen, onClose: onHelpClose } = useDisclosure()
  const cleansetInfo = useContext(CleansetContext)

  const { trackEvent } = useEventTracking()

  const openHelpModal = useCallback(() => {
    onHelpOpen()
    void trackEvent(MixpanelEvents.pressHelpKeyResolverPanel, {
      ...cleansetInfo,
      isTemplate: isTemplate,
    })
  }, [cleansetInfo, onHelpOpen, isTemplate, trackEvent])

  useKeyPress({ callback: openHelpModal, keys: ['h'] })

  return (
    <div className="flex w-full flex-row justify-between px-6 py-5">
      <div className="flex items-center gap-4">
        <Tooltip content="Press [esc] to open/close Resolver">
          <IconFrameButton
            size="xSmall"
            icon={<IconArrowRight />}
            variant="level0"
            aria-label="close resolver"
            onClick={() => setEditMode(false)}
          />
        </Tooltip>
        <p className="type-overline">RESOLVER</p>
      </div>
      <div className="flex flex-row gap-4">
        {!isMultiSelect && (
          <FormControl display="flex" alignItems="center" width="auto">
            <div className="flex flex-row gap-4">
              <Switch
                id="auto-advance"
                isChecked={autoAdvance}
                size="md"
                onChange={() => {
                  setAutoAdvance(!autoAdvance)
                }}
              />

              <p className="type-body-100-medium text-text-primary">Auto-advance</p>
            </div>
          </FormControl>
        )}
        <Button variant="secondary" size="small" iconEnd={<Kbd>H</Kbd>} onClick={openHelpModal}>
          Help
        </Button>
      </div>
      <HelpModal isOpen={isHelpOpen} onClose={onHelpClose} />
    </div>
  )
}

export default ResolverHeading
